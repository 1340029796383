import React from 'react'
import {
  DatePicker,
  FieldValues,
  Grid,
  Input,
  Modal,
  SegmentedControl,
  UseFormReturn,
  yup,
} from '@quipu/components'
import { CategoryField } from '../CategoryField'
import { TagField } from '../TagField'

interface File {
  id: string
  value: string
}

export interface TransactionFormValues {
  name: string
  categoryId?: string
  tags?: string[]
  amount: string
  date: string
  notes?: string
  files?: File[]
  draft?: boolean
  type: string
}

interface TransactionFormProps<TFieldValues extends FieldValues>
  extends UseFormReturn<TFieldValues> {}

export const transactionFormSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  categoryId: yup.string(),
  tags: yup.array().of(yup.string().required()),
  amount: yup.string().required('Amount is required'),
  date: yup.string().required('Date is required'),
  notes: yup.string(),
  files: yup.array().of(yup.mixed<File>().required()),
  draft: yup.boolean(),
  type: yup.string().required('Type is required'),
})

export const TransactionForm = (
  methods: TransactionFormProps<TransactionFormValues>
) => {
  const { setValue, getValues } = methods
  // const [uploadingFile, setUploadingFile] = useState(false)

  return (
    <Modal.PaddedContent top>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <SegmentedControl.Root
            value={methods.watch('type')}
            onValueChange={value => setValue('type', value)}
          >
            <SegmentedControl.Segment value="EXPENSE">
              Expense
            </SegmentedControl.Segment>
            <SegmentedControl.Segment value="INCOME">
              Income
            </SegmentedControl.Segment>
          </SegmentedControl.Root>
        </Grid>
        <Grid xs={12}>
          <Input.FormField name="name" label="Name" required />
        </Grid>
        <Grid xs={12}>
          <CategoryField
            name="categoryId"
            label="Category"
            value={methods.watch('categoryId')}
            onChange={option =>
              setValue(
                'categoryId',
                !Array.isArray(option) ? option?.value : undefined
              )
            }
          />
        </Grid>
        <Grid xs={12}>
          <TagField
            name="tags"
            label="Tags"
            value={methods.watch('tags')}
            onChange={options => {
              const optionValues = Array.isArray(options)
                ? options.map(option => option.value)
                : undefined
              setValue('tags', optionValues)
            }}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Input.CustomFormField label="Date" name="date" required>
            {methods => (
              <DatePicker
                startDate={methods.watch('date')}
                onChange={date => {
                  if (!date) return

                  setValue('date', date.toISOString())
                }}
                // TODO: Review these extra props
                excludeScrollbar={true}
                onSelect={() => {}}
              />
            )}
          </Input.CustomFormField>
        </Grid>

        <Grid xs={12} sm={6}>
          {/* TODO: Create a price field */}
          <Input.FormField
            name="amount"
            label="Amount"
            required={!getValues('draft' as any)}
          />
        </Grid>

        <Grid xs={12} sm={12}>
          <Input.FormField
            name="notes"
            label="Notes"
            placeholder="Additional notes"
            type="textarea"
          />
        </Grid>

        {/*
        <Grid xs={12}>
          TODO: Create FileUploadField
           <FileUploadField
              name="files"
              label="Files"
              onUploadStart={() => setUploadingFile(true)}
              onUploadEnd={() => setUploadingFile(false)}
            />
        </Grid>
        */}
      </Grid>
    </Modal.PaddedContent>
  )
}
