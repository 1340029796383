import React from 'react'
import { ApolloProvider, ApolloClient } from '@apollo/client'
import { ClientConfiguration, buildClient } from './client'

// DO NOT export this
let _client: ApolloClient<unknown> | null = null

export interface GraphqlProviderProps extends React.PropsWithChildren {
  config: ClientConfiguration
}

const getClient = (config: ClientConfiguration): ApolloClient<unknown> => {
  if (_client) return _client

  _client = buildClient(config)
  return _client
}

export const GraphqlProvider = (props: GraphqlProviderProps) => {
  const { config, children } = props
  const clientValue = getClient(config)

  return (
    <ApolloProvider client={clientValue}>
      <>{children}</>
    </ApolloProvider>
  )
}

export * from '@apollo/client'
