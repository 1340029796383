import React, { PropsWithChildren, useMemo } from 'react'
import { incId } from '../../utils'

type ForwardProps = Partial<React.ComponentPropsWithRef<'input'>> &
  Partial<React.ComponentPropsWithRef<'textarea'>>

export type InputContext = {
  id: string | null
  // TODO: over time check which states do we need. It might be nice to pass down
  // more states like loading, isDirty etc.
  // but on the other hand - we can already pass aria-busy="true", disabled states
  // and style things based on that.
  error?: { message?: string }
  /**
   * Pass down props to the underlying input element.
   */
  forward: ForwardProps
}
const inputContext = React.createContext<InputContext>({
  id: null,
  error: undefined,
  forward: {},
})

export const useInputContext = () => {
  const context = React.useContext(inputContext)

  return context
}

interface ProviderProps extends Partial<InputContext>, PropsWithChildren {}

export const Provider = (props: ProviderProps) => {
  const { children, id: idPassed, error, forward = {} }: typeof props = props
  const defaultId = useMemo(incId, [])
  const id = idPassed ?? defaultId

  const ariaError = error
    ? ({
        'aria-invalid': true,
        'aria-describedby': `${id}-err`,
      } satisfies ForwardProps)
    : {}

  return (
    <inputContext.Provider
      value={{ error, forward: { ...ariaError, ...forward }, id }}
    >
      {children}
    </inputContext.Provider>
  )
}
