import React, { FC, useEffect } from 'react'

import { Sidebar } from './Sidebar'
import { cs, useToast } from '@quipu/components'
import { useAccount } from '../../context'
import * as s from './Layout.styles'
import { Routes } from '@quipu/modules-common'

interface LayoutProps extends React.PropsWithChildren {
  header?: React.ReactNode
  alignItems?: 'left' | 'center'
  contentContainerStyleOverrides?: any
  containerStyleOverrides?: any
  hideSidebarNavigation?: boolean
}

export const Layout: FC<LayoutProps> = ({
  header,
  alignItems = 'left',
  contentContainerStyleOverrides,
  containerStyleOverrides,
  hideSidebarNavigation,
  children,
}) => {
  const { user } = useAccount()
  const { showToast } = useToast()

  useEffect(() => {
    // Check account activation state
    if (!user?.active && user?.activationToken) {
      showToast(
        {
          description: (
            <>
              Your account is not yet activated. Please click on the{' '}
              <a
                href={Routes.routes.ACTIVATE_ACCOUNT.create({
                  token: user.activationToken,
                })}
              >
                here
              </a>{' '}
              to activate.
            </>
          ),
        },
        'neutral'
      )
    }
  }, [showToast, user])

  return (
    <main className={s.mainContainer}>
      <Sidebar hideMenuItems={hideSidebarNavigation} />

      <div className={cs(s.contentContainer, contentContainerStyleOverrides)}>
        {header ? (
          <header className={s.header}>
            <div
              id="layout-header-portal"
              style={{ display: 'flex', flex: 1 }}
            ></div>
            {header}
          </header>
        ) : null}

        <div
          className={cs(
            s.container,
            containerStyleOverrides,
            alignItems === 'center' && s.alignCenter
          )}
        >
          {children}
        </div>
      </div>
    </main>
  )
}
