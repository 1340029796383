import React from 'react'
import { cs } from '../../utils'

import { LinkBase, sizeSmall, sizeMedium } from './Link.styles'

type Size = 'small' | 'medium'

const SIZE_STYLES = {
  small: sizeSmall,
  medium: sizeMedium,
}

export interface LinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  size?: Size | number
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  function Link({ className, size = 'medium', ...otherProps }, ref) {
    return (
      <LinkBase
        ref={ref}
        className={cs(
          typeof size === 'string' ? SIZE_STYLES[size] : undefined,
          className
        )}
        style={{ fontSize: typeof size === 'number' ? size : undefined }}
        {...otherProps}
      />
    )
  }
)
