import { Close as DialogClose } from '@radix-ui/react-dialog'
import React, { PropsWithChildren } from 'react'

import { Button, ButtonProps } from '../../Button'

export interface CloseProps extends PropsWithChildren, ButtonProps {}

const Close: React.FC<CloseProps> = props => {
  const { children, ...rest } = props
  return (
    <DialogClose asChild>
      <Button type="button" variant="tertiary" {...rest}>
        {children}
      </Button>
    </DialogClose>
  )
}

Close.defaultProps = {
  children: 'Cancel',
}

export { Close }
