import React, { PropsWithChildren } from 'react'

import { Button, ButtonProps } from '../../Button'

export interface SubmitProps extends PropsWithChildren, ButtonProps {}

const Submit: React.FC<SubmitProps> = props => {
  const { children, ...rest } = props
  return (
    <Button type="submit" variant="primary" {...rest}>
      {children}
    </Button>
  )
}

Submit.defaultProps = {
  children: 'Submit',
}

export { Submit }
