import React, { PropsWithChildren } from 'react'

import { ModalsMountContext } from './ModalsMountContext'
import { renderModal } from './renderModal'
import { useModalsState } from './useModalsState'

/**
 * ModalsProvider
 *
 * Renders the modal provider and maps an array of Modals so we can
 * render multiple modals if needed.
 *
 */
export const ModalsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { modals, manageModals } = useModalsState()

  return (
    <ModalsMountContext.Provider value={manageModals}>
      {children}
      {modals.map(modal => renderModal(modal, manageModals))}
    </ModalsMountContext.Provider>
  )
}
