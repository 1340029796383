import React from 'react'
import moment from 'moment'

import { Form, Modal, useForm, yupResolver, Button } from '@quipu/components'
import { useMutation } from '@quipu/apollo'
import { CreateBudgetDocument } from '@quipu/graphql'
import { BudgetForm, budgetFormSchema, BudgetFormValues } from './BudgetForm'

interface CreateBudgetModalProps {
  projectId: string
  dismissModal: () => void
}

const defaultValues: BudgetFormValues = {
  startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
  endDate: '',
  totalAmount: '',
  recurrent: false,
  projectId: '',
}

export const CreateBudgetModal = ({
  projectId,
  dismissModal,
}: CreateBudgetModalProps) => {
  const [createBudget] = useMutation(CreateBudgetDocument, {
    onCompleted: dismissModal,
  })

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(budgetFormSchema),
    defaultValues: { ...defaultValues, projectId },
  })

  const handleSubmit = async (values: typeof defaultValues) => {
    createBudget({
      variables: {
        input: {
          ...values,
          endDate: values.endDate || undefined,
          totalAmount: parseFloat(values.totalAmount),
          projectId,
          // TODO: Implement budget periodicity
          recurrent: false,
        },
      },
    })
  }

  return (
    <Modal.Card>
      <Form {...methods} onSubmit={handleSubmit}>
        <Modal.PaddedContent all>
          <Modal.Title>Add a budget</Modal.Title>

          <BudgetForm {...methods} />
        </Modal.PaddedContent>

        <Modal.Footer>
          <Modal.Close />
          <Button type="submit">Add</Button>
        </Modal.Footer>
      </Form>
    </Modal.Card>
  )
}
