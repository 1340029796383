import React, { PropsWithChildren } from 'react'
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form'

interface FormProps<TFieldValues extends FieldValues>
  extends UseFormReturn<TFieldValues>,
    PropsWithChildren {
  onSubmit: (data: TFieldValues) => Promise<void>
}

export const Form = <TFieldValues extends FieldValues>({
  children,
  onSubmit,
  ...methods
}: FormProps<TFieldValues>) => {
  const { handleSubmit } = methods

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  )
}
