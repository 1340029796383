import { css } from '@linaria/core'
import { colors } from '@quipu/style-foundations'

export const container = css`
  display: flex;
  flex-direction: column;
`

export const menuButton = css`
  display: flex;
  flex-direction: column;
  height: 9rem;
  justify-content: center;
  align-items: center;

  font-size: 1.2rem;
  text-decoration: none;
  color: ${colors.black};
  border-left: 0.4rem solid transparent;
  padding: 0 3rem;

  svg {
    width: 3.2rem;
    height: 3.2rem;
    margin-bottom: 0.8rem;
  }

  :hover {
    cursor: pointer;
    color: ${colors.green};
    background: ${colors.green10};
    border-left: 0.4rem solid ${colors.green};
  }
`

export const menuButtonActive = css`
  color: ${colors.green};
  background: ${colors.green10}};
  border-left: 4px solid ${colors.green};
`

export const menuButtonText = css`
  text-align: center;
  padding-right: 0.4rem;
`
