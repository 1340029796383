import { useEffect } from 'react'

/**
 * Activate Dark Mode if the OS setting changes
 */
export const useSystemDarkMode = (
  onDarkMode: (e: MediaQueryListEvent) => void
) => {
  useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', onDarkMode)

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', onDarkMode)
    }
  }, [onDarkMode])
}
