import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

// Global styles and resets for the app to work properly
// with the design system
import '@quipu/style-foundations/styles.css'

import { App } from './root/App'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement!)

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)
