import { css } from '@linaria/core'
import { colors } from '@quipu/style-foundations'

export const container = css`
  display: flex;
  border-width: 0.2rem;
  border-radius: 0.8rem;
  border-color: ${colors.black20};
`

export const clickableContainer = css`
  :hover {
    background: hsl(180, 29%, 94%);
    cursor: pointer;
  }
`

export const variantPrimary = css`
  box-shadow: 0 0.5rem 0.5rem -0.3rem hsla(0, 0%, 0%, 0.16),
    0 0.8rem 1rem 0.1rem hsla(0, 0%, 0%, 0.05),
    0 0.3rem 1.4rem 0.2rem hsla(0, 0%, 0%, 0.08);
`

export const dottedBorder = css`
  border-style: dotted;
`

export const solidBorder = css`
  border-style: solid;
`
