import { css } from '@linaria/core'

export const container = css`
  display: grid;
  width: 100%;
  gap: var(--spacing);

  @media (min-width: 768px) {
    grid-template-columns: repeat(12, 1fr);
  }
`

export const item = css`
  width: 100%;
  grid-column: span 12;

  @media (min-width: 768px) {
    &.sm-2 {
      grid-column: span 2;
    }

    &.sm-4 {
      grid-column: span 4;
    }

    &.sm-6 {
      grid-column: span 6;
    }

    &.sm-8 {
      grid-column: span 8;
    }

    &.sm-12 {
      grid-column: span 12;
    }
  }
`
