import React, { FC } from 'react'

import {
  Form,
  FormProvider,
  Grid,
  Input,
  Modal,
  ModalInjectedProps,
  useForm,
  yup,
  yupResolver,
} from '@quipu/components'
import moment from 'moment'
import { useMutation } from '@quipu/apollo'
import {
  CreateCategoryDocument,
  GetCategoriesDocument,
  UpdateCategoryDocument,
} from '@quipu/graphql'
// import { ColorPickerField } from '../ColorPickerField'

interface EditCategoryModalProps extends ModalInjectedProps {
  edit: true
  id: string
  name: string
  color?: string
}

interface CategoryModalProps extends ModalInjectedProps {
  edit?: false
}

const schema = yup.object().shape({
  workspaceId: yup.string(),
  name: yup.string().required(),
  date: yup.string().required(),
})

const defaultValues = {
  name: '',
  date: moment().format('YYYY-MM-DD HH:mm:ss'),
}

export const CategoryModal: FC<CategoryModalProps | EditCategoryModalProps> = ({
  dismissModal,
  ...props
}) => {
  // TODO: Replace this localStorage.getItem with a custom one
  const activeWorkspaceId = localStorage.getItem('activeWorkspace')!

  const [createCategory] = useMutation(CreateCategoryDocument, {
    onCompleted: () => {
      dismissModal()
    },
    refetchQueries: [
      {
        query: GetCategoriesDocument,
        variables: {
          workspaceId: activeWorkspaceId,
        },
      },
    ],
  })

  const [updateCategory] = useMutation(UpdateCategoryDocument, {
    onCompleted: () => {
      dismissModal()
    },
    refetchQueries: [
      {
        query: GetCategoriesDocument,
        variables: {
          workspaceId: activeWorkspaceId,
        },
      },
    ],
  })

  const handleSubmit = async (values: any) => {
    if (props.edit) {
      updateCategory({
        variables: {
          id: props.id,
          input: {
            name: values.name,
            color: values.color,
          },
        },
      })
    } else {
      createCategory({
        variables: {
          input: {
            name: values.name,
            color: values.color,
            workspace: activeWorkspaceId,
          },
        },
      })
    }
  }

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues,
  })

  return (
    <FormProvider {...methods}>
      <Form {...methods} onSubmit={handleSubmit}>
        <Modal.Card title="Edit Category">
          <Modal.PaddedContent>
            <Grid container spacing={32}>
              <Grid xs={12}>
                <Input.FormField
                  name="name"
                  label="Name"
                  placeholder="Category name"
                  required
                />
              </Grid>
              <Grid xs={12}>
                {/* <ColorPickerField name="color" label="Color" /> */}
              </Grid>
            </Grid>
          </Modal.PaddedContent>
          <Modal.Footer>
            <Modal.Close />
            <Modal.Submit>Create Category</Modal.Submit>
          </Modal.Footer>
        </Modal.Card>
      </Form>
    </FormProvider>
  )
}
