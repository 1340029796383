import { generatePath, Route, RouteProps } from 'react-router-dom'

// taken from https://stackoverflow.com/a/72331909
type _UnwrapParam<
  P extends string,
  S extends string[],
> = P extends `:${infer Q}` ? [Q, ...S] : S

type _Match<
  T extends string,
  S extends string[],
> = T extends `${infer P}/${infer R}`
  ? _Match<R, _UnwrapParam<P, S>>
  : _UnwrapParam<T, S>

export type Match<T extends string> = _Match<T, []>[number]

export interface TypedRouteCompProps<T extends UntypedRoutes>
  extends OmitSafe<RouteProps, 'path'> {
  path?: T[keyof T]
}

export type TypedRouteComp<T extends UntypedRoutes> = (
  props: TypedRouteCompProps<T>
) => React.ReactElement | null

export type ExtractRouteParams<T extends string> = {
  [K in Match<T>]: string
}

export class TypedRoute<T extends string> {
  constructor(readonly template: T) {}

  create = (params: ExtractRouteParams<T>) => {
    return generatePath(this.template, params as any)
  }
}

export interface UntypedRoutes {
  [key: string]: string
}

export type TypedRoutes<T extends UntypedRoutes> = {
  [key in keyof T]: TypedRoute<T[key]>
}

export interface CreateRoutesResult<T extends UntypedRoutes> {
  routes: TypedRoutes<T>
  Route: TypedRouteComp<T>
  routesRaw: T
}

export const createRoutes = <const T extends UntypedRoutes>(
  routes: T
): CreateRoutesResult<T> => {
  const typedRoutes = Object.fromEntries(
    Object.entries(routes).map(([key, path]) => [key, new TypedRoute(path)])
  ) as TypedRoutes<T>

  return {
    routes: typedRoutes,
    routesRaw: routes,
    Route: Route as TypedRouteComp<T>,
  }
}
