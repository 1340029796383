import { css } from '@linaria/core'
import { colors } from '@quipu/style-foundations/src'

export const textArea = css`
  display: block;
  border: 1px solid ${colors.black10};
  padding: 12px 0 12px 16px;
  border-radius: 8px;
  background-color: white;
  width: 100%;

  font-size: 1.4rem;
  font-weight: 500;
`
