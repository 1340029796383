import React, { PropsWithChildren } from 'react'
import {
  FieldPath,
  FieldValues,
  FormState,
  UseFormRegister,
} from 'react-hook-form'
import { Provider } from './inputContext'

type ForwardFromRoot = Partial<
  OmitSafe<React.ComponentPropsWithoutRef<'input'>, keyof UseFormRegister<{}>>
>
export interface InputRootProps<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends PropsWithChildren {
  name: TFieldName
  register: UseFormRegister<TFieldValues>
  formState: FormState<TFieldValues>
  /**
   * Pass down props to the underlying input element.
   */
  forward?: ForwardFromRoot
}

export function InputRoot<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: InputRootProps<TFieldValues, TFieldName>) {
  const {
    children,
    name,
    formState,
    forward = {},
    register,
  }: typeof props = props

  const error = formState.errors[name] as { message?: string }

  return (
    <Provider forward={{ ...register(name), ...forward }} error={error}>
      {children}
    </Provider>
  )
}
