import React from 'react'

import { Providers } from './Providers'
import Router from './Router'

export const App = () => {
  return (
    <Providers>
      <Router />
    </Providers>
  )
}
