import { useContext } from 'react'
import { ModalInjectedProps, ModalsMountContext } from './ModalsMountContext'
import { DialogProps } from '@radix-ui/react-dialog'

type PassComponentProps<T extends ModalInjectedProps> = {
  /**
   * You can pass a Modal component and props for it.
   * However, note that changing props / rerending component where
   * `showModal` was invoked will NOT rerender the Modal.
   */
  component: React.ComponentType<T>
  element?: never
} & RequireIfNotPartial<'props', OmitSafe<T, keyof ModalInjectedProps>>

interface PassElementProps {
  element: React.ReactNode
  props?: never
  component?: never
}

type MountProps<M extends DialogProps> = {
  /**
   * You can pass a custom Modal Mount and props for it.
   * However, note that changing props / rerending component where
   * `showModal` was invoked will NOT rerender the Modal Mount (nor the modal itself).
   *
   * If you want to share state between the Modal Mount and the Modal - consider to use
   * React context.
   */
  mount?: React.ComponentType<M>
} & RequireIfNotPartial<'mountProps', OmitSafe<M, keyof DialogProps>>

export type ShowModalProps<
  T extends ModalInjectedProps = ModalInjectedProps,
  M extends DialogProps = DialogProps,
> = (PassComponentProps<T> | PassElementProps) & MountProps<M>

export interface ShowModalValue {
  showModal: <
    T extends ModalInjectedProps,
    M extends DialogProps = DialogProps,
  >(
    props: ShowModalProps<T, M>
  ) => () => void
}

export const useModal = (): ShowModalValue => {
  const context = useContext(ModalsMountContext)

  if (!context) return { showModal: () => () => {} }

  const { addModal, removeModal: dismissModal } = context

  const showModal = <T extends ModalInjectedProps, M extends DialogProps>(
    props: ShowModalProps<T, M>
  ) => {
    const { mount, mountProps, element } = props
    const component = props.component
      ? { comp: props.component, props: props.props }
      : undefined

    const { id } = addModal({ component, element, mount, mountProps })
    return () => dismissModal(id)
  }

  return { showModal }
}
