import React from 'react'
import { labelCss, sizeSmall, sizeMedium } from './InputLabel.styles'
import { cs } from '../../utils'
import { Primitive } from '../Primitives'
import { useInputContext } from './inputContext'

const SIZE_STYLES = {
  small: sizeSmall,
  medium: sizeMedium,
}

type Size = 'small' | 'medium'

interface LabelRef extends Primitive.LabelRef {}
interface LabelProps extends Primitive.LabelProps {
  size?: Size
}

export const InputLabel = React.forwardRef<LabelRef, LabelProps>(
  (props, ref) => {
    const { children, className, size = 'medium', ...rest } = props
    const { id } = useInputContext()

    return (
      <Primitive.label
        ref={ref}
        {...rest}
        className={cs(className, labelCss, SIZE_STYLES[size as Size])}
        htmlFor={id || undefined}
      >
        {children}
      </Primitive.label>
    )
  }
)
InputLabel.displayName = 'InputLabel'
