import { css } from '@linaria/core'
import { colors } from '@quipu/style-foundations'

export const insetLeftName = '--input-inset-left'
export const insetRightName = '--input-inset-right'

export const sizeSmall = css`
  padding: 0.6rem 1.2rem;
  padding-left: calc(var(${insetLeftName}, 0rem) + 1.2rem);
  padding-right: calc(var(${insetRightName}, 0rem) + 1.2rem);
  border-radius: 0.7rem;
  font-size: 1.2rem;
  line-height: 1.33;
`

export const sizeMedium = css`
  padding: 1rem 1.2rem;
  padding-left: calc(var(${insetLeftName}, 0rem) + 1.2rem);
  padding-right: calc(var(${insetRightName}, 0rem) + 1.2rem);
  border-radius: 0.9rem;
  font-size: 1.3rem;
`

export const inputCss = css`
  display: block;
  font-weight: 500;

  color: ${colors.black85};
  background-color: ${colors.black5};
  border: 1px solid transparent;

  &:placeholder-shown {
    color: ${colors.black35};
  }

  &:focus {
    border: 1px solid ${colors.black85};
    background-color: ${colors.white};
  }

  &:disabled {
    color: ${colors.black15};
    background-color: ${colors.black3};
    cursor: not-allowed;
  }

  &:not([readonly]):hover {
    outline: 1px solid ${colors.black85};
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .dark & {
    color: ${colors.white85};
    background-color: ${colors.white5};

    &:placeholder-shown {
      color: ${colors.white25};
    }

    &:focus {
      border: 1px solid ${colors.white85};
      background-color: ${colors.black};
    }

    &:disabled {
      color: ${colors.white25};
      background-color: ${colors.white5};
      cursor: not-allowed;
    }

    &:not([readonly]):hover {
      outline: 1px solid ${colors.white85};
    }
  }
`

export const errorCss = css`
  font-size: 1.4rem;
  line-height: 1.4rem;
  min-height: 1.4rem;
  color: hsl(3, 92%, 56%);
  display: block;
`
