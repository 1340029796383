import { colors } from '@quipu/style-foundations'
import { Item as ToggleGroupItem } from '@radix-ui/react-toggle-group'
import { styled } from '@linaria/react'

export const Segment = styled(ToggleGroupItem)`
  width: 100%;
  z-index: 2;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  color: ${colors.black50};
  background: transparent;

  border-radius: 0.5rem;
  padding: 0.8rem 2.4rem;
  font-size: 1.3rem;

  [data-segmented-control-size='small'] & {
    font-size: 1.2rem;
    padding: 0.4rem 1.2rem;
  }

  &:hover {
    background-color: ${colors.black5};
  }

  &:focus,
  &:focus-visible {
    z-index: 3;
    outline: 0.2rem solid ${colors.black25};
  }

  &:active {
    color: ${colors.black85};
  }

  &[aria-checked='true'] {
    color: ${colors.black85};
    background-color: ${colors.white};
    box-shadow: 0 1px 3px 0 ${colors.black10};
    cursor: default;
  }

  .dark & {
    color: ${colors.white55};
    background: transparent;

    &:hover {
      background-color: ${colors.black5};
    }

    &:focus,
    &:focus-visible {
      outline: 0.2rem solid ${colors.white25};
    }

    &:active {
      color: ${colors.white85};
    }

    &[aria-checked='true'] {
      color: ${colors.black85};
      background-color: ${colors.white85};
      box-shadow: 0 1px 3px 0 ${colors.black10};
    }
  }
`
