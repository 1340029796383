import { css } from '@linaria/core'
import { HIDE_ANIMATION_DURATION } from './ToastProvider'
import { colors } from '@quipu/style-foundations/src'

export const neutral = css`
  color: ${colors.black30};
`

export const positive = css`
  color: ${colors.green};
`

export const negative = css`
  color: ${colors.red};
`

const slideRight = `
  @keyframes slideRight {
    from {
      transform: translateX(var(--radix-toast-swipe-end-x));
    }
    to {
      transform: translateX(100%);
    }
  }

  animation: slideRight 150ms ease-in reverse;
`

const hide = `
  @keyframes hide {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 0;
    }
    100% {
      margin-top: calc((var(--height) + var(--gap)) * -1);
      opacity: 0;
    }
  }

  animation: hide ${HIDE_ANIMATION_DURATION}ms cubic-bezier(0.51, 0.13, 0.35, 0.92);
`

const fadeOut = `
  @keyframes fadeOut {
    0% {
      transform: translate(var(--radix-toast-swipe-end-x), 0);
      opacity: 1;
    }
    100% {
      transform: translate(100%, var(--height));
      margin-top: calc((var(--height)) * -1);
      opacity: 0;
    }
  }

  animation: fadeOut ${HIDE_ANIMATION_DURATION}ms cubic-bezier(0.51, 0.13, 0.35, 0.92);
`

export const viewport = css`
  --gap: 25px;

  position: fixed;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  gap: var(--gap);

  width: 400px;
  max-width: 100vw;
  padding: 20px;
  margin: 0;

  list-style: none;
  z-index: 9999;
  outline: none;
`

export const root = css`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: var(--gap);

  margin-top: 0;
  padding: 1.6rem;

  color: ${colors.black};
  background-color: ${colors.white};
  border: 1px solid ${colors.black10};

  border-radius: 1.6rem;
  box-shadow: 0.5px 0 16px -10px ${colors.black85};

  :hover {
    box-shadow: 0 0.1rem 0.3rem 0 ${colors.black25};
  }

  &:focus,
  &:focus-visible {
    box-shadow: 0 0.1rem 0.3rem 0 ${colors.black10};
    outline: 0.2rem solid ${colors.black25};
  }

  /* Runs when toast appears */
  &[data-state='open'] {
    ${slideRight};
  }

  /* Runs when the user closes the toast without swipping */
  &[data-state='closed'] {
    ${hide};
    z-index: -1;
  }

  /* Runs when user swipes the toast */
  &[data-swipe='move'] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }

  /* Runs when user cancels the swipe */
  &[data-swipe='cancel'] {
    transform: translateX(0);
  }

  /* Runs when the user swipes the toast to the right */
  &[data-swipe='end'] {
    ${fadeOut};
  }
`

export const toastContent = css`
  flex: 1;
  display: flex;
  align-items: center;
  user-select: text;
`

export const toastIcon = css`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 8px;
  display: inline-block;

  /* TODO: Remove this when we have icons */
  content: '';
`

export const toastTitle = css`
  font-size: 2rem;
  font-weight: bold;
  padding-bottom: 0.8rem;
`

export const toastDescription = css`
  font-size: 1.4rem;

  /* FIXME: This is temporary. We don't have the final icons
  and this is not the final font.
  The margin is to center the icon with the text. */
  margin-top: 0.2rem;
`

export const toastClose = css`
  display: block;
  font-size: 2.4rem;
  color: ${colors.black55};
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: ${colors.black};
  }
`
