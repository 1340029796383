import React from 'react'
import moment from 'moment'
import {
  Form,
  FormProvider,
  Modal,
  useForm,
  yup,
  yupResolver,
} from '@quipu/components'
import { useMutation } from '@quipu/apollo'
import { CreateProjectDocument, GetProjectsDocument } from '@quipu/graphql'
import { ProjectFormContent } from './ProjectFormContent'

const schema = yup.object().shape({
  workspaceId: yup.string(),
  name: yup.string().required(),
  date: yup.string().required(),
})

const defaultValues = {
  name: '',
  date: moment().format('YYYY-MM-DD'),
}

interface CreateProjectForm {
  dismissModal: () => void
}

export const CreateProjectForm = ({ dismissModal }: CreateProjectForm) => {
  // TODO: Replace this localStorage.getItem with a custom one
  const activeWorkspaceId = localStorage.getItem('activeWorkspace')!

  const [createProject] = useMutation(CreateProjectDocument, {
    onCompleted: dismissModal,
    refetchQueries: [
      {
        query: GetProjectsDocument,
        variables: {
          workspaceId: activeWorkspaceId,
        },
      },
    ],
  })

  const initialValues = { ...defaultValues, workspaceId: activeWorkspaceId }
  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  })

  const handleSubmit = async ({ date, ...values }: any) => {
    const commonFormData = {
      ...initialValues,
      ...values,
      date: moment(date).format('YYYY-MM-DD HH:mm:ss'),
    }

    createProject({
      variables: {
        input: commonFormData,
      },
    })
  }

  return (
    <FormProvider {...methods}>
      <Form {...methods} onSubmit={handleSubmit}>
        <Modal.Card>
          <Modal.PaddedContent all>
            <Modal.Title>Create Project</Modal.Title>

            <ProjectFormContent />
          </Modal.PaddedContent>

          <Modal.Footer>
            <Modal.Close />
            <Modal.Submit>Create Project</Modal.Submit>
          </Modal.Footer>
        </Modal.Card>
      </Form>
    </FormProvider>
  )
}
