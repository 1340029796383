import React, { useEffect, useState } from 'react'
import { useNavigate, matchPath, useLocation } from 'react-router-dom'
import { ApolloError, useQuery } from '@quipu/apollo'

import {
  Button,
  Form,
  Grid,
  Input,
  Link,
  useForm,
  yup,
  yupResolver,
} from '@quipu/components'
import { Routes } from '@quipu/modules-common'
import { useAccount } from '../../context'

import * as S from './SignUpView.styles'
import { VerifyWorkspaceInvitationDocument } from '@quipu/graphql'

const routes = Routes.routes

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup
    .string()
    // default email validation is too permissible, this one requires at least one @ and domain
    .matches(/.+@.+\..+/, 'Please enter a valid email address')
    .required('Email is required'),
  password: yup.string().required(),
})

const initialFormData = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
}

export const SignUpView = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { logOut, register: registerMutation } = useAccount()

  const isFromAcceptInvitation = location.state?.from?.pathname
    ? matchPath(
        location.state?.from?.pathname,
        Routes.routes.WORKSPACE_ACCEPT_INVITATION.template
      )
    : false

  const { data: invitationData, loading: invitationDataLoading } = useQuery(
    VerifyWorkspaceInvitationDocument,
    {
      variables: {
        token: String(
          isFromAcceptInvitation && isFromAcceptInvitation.params.token
        ),
      },
      skip: !isFromAcceptInvitation,
    }
  )

  useEffect(() => {
    if (isFromAcceptInvitation) {
      logOut()
    }
  }, [isFromAcceptInvitation, logOut])

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const initialFormValues = isFromAcceptInvitation
    ? {
        ...initialFormData,
        email: invitationData?.verifyWorkspaceInvitation?.email!,
      }
    : undefined

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: initialFormValues,
  })

  const submit = async (data: any) => {
    try {
      setLoading(true)

      await registerMutation(data)

      if (location?.state?.from) {
        navigate(location.state.from.pathname)
      } else {
        navigate(Routes.routes.ROOT.create({}))
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        setError(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  let title = ''
  if (isFromAcceptInvitation) {
    title = `You have been invited to join "${invitationData?.verifyWorkspaceInvitation?.workspace?.name}" workspace.`
  }

  return (
    <S.Container>
      <S.FormContainer>
        <S.Logo />
        <S.Title>{title}</S.Title>
        <S.SubTitle>
          To get started,{' '}
          <Link
            size={18}
            href={routes.SIGN_IN.create({})}
            onClick={event => {
              event.preventDefault()
              navigate(Routes.routes.SIGN_IN.create({}), {
                state: location.state,
              })
            }}
          >
            sign in
          </Link>{' '}
          or create an account.
        </S.SubTitle>
        {invitationDataLoading ? (
          <>Loading...</>
        ) : (
          <Form {...methods} onSubmit={submit}>
            <Grid gap="2">
              <div>
                <Input.FormField name="firstName" label="First Name" required />
              </div>
              <div>
                <Input.FormField name="lastName" label="Last Name" required />
              </div>
              <div>
                <Input.FormField
                  name="email"
                  label="Email"
                  required
                  readOnly={Boolean(isFromAcceptInvitation)}
                  disabled={Boolean(isFromAcceptInvitation)}
                />
              </div>
              <div>
                <Input.FormField
                  type="password"
                  name="password"
                  label="Create a Password"
                  required
                />
              </div>

              {error && (
                <div>
                  <S.Error>{error}</S.Error>
                </div>
              )}
              <div>
                <Button type="submit" disabled={loading} fullWidth>
                  Sign Up
                </Button>
              </div>
            </Grid>
          </Form>
        )}
      </S.FormContainer>
    </S.Container>
  )
}
