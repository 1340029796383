import { styled } from '@linaria/react'
import { colors } from '@quipu/style-foundations'

export const Container = styled.div`
  height: 100%;
  background: hsl(180, 30%, 96%);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FormContainer = styled.div`
  width: 67rem;
  display: flex;
  flex-direction: column;
  padding: 5.6rem 12rem;
  background: #ffffff;
  border-radius: 1.6rem;
`

export const Logo = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  align-self: center;
  margin-bottom: 4rem;
  background: #e3e3e3;
`

export const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5;
`
export const SubTitle = styled.h2`
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 3.2rem;
`

export const Error = styled.div`
  display: flex;
  font-size: 14px;
  color: ${colors.red};
`
