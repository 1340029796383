import { InMemoryCache } from '@apollo/client'
import {
  TypedTypePolicies,
  possibleTypes as possibleTypesRaw,
} from '@quipu/graphql'

import { dataIdFromObject } from './dataIdFromObject'
const { possibleTypes } = possibleTypesRaw

interface CacheEntry {
  __ref: string
}

interface CacheEntries {
  entries?: CacheEntry[]
}

const mergeEntries = (
  existing: CacheEntries = {},
  incoming: CacheEntries
): CacheEntry[] => {
  const existingEntries = existing?.entries ?? []
  let newEntries: CacheEntry[] = []

  if (incoming?.entries?.length) {
    newEntries = incoming?.entries?.reduce(
      (acc: CacheEntry[], curr: CacheEntry) =>
        existingEntries.find(({ __ref }) => __ref === curr.__ref)
          ? acc
          : [...acc, curr],
      []
    )
  }

  return [...existingEntries, ...newEntries]
}

// We are using typePolicies to handle pagination
const typePolicies: TypedTypePolicies = {
  Workspace: {
    fields: {
      members: {
        // When searching we want to clear cache, so by adding 'search'
        // here, given that "search" is the parameter we send on the query,
        // we tell apollo to store these results with a different cache key.
        keyArgs: ['search'],
        merge(existing = {}, incoming) {
          return {
            ...incoming,
            entries: mergeEntries(existing, incoming),
          }
        },
      },
    },
  },
}

export const buildCache = () => {
  return new InMemoryCache({
    possibleTypes,
    typePolicies,
    dataIdFromObject,
  })
}
