import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import * as s from './UserMenu.styles'
import { Routes } from '@quipu/modules-common'
import { Avatar, Dropdown } from '@quipu/components'
import { useAccount } from '../../../context'

export const UserMenu: FC = () => {
  const navigate = useNavigate()
  const { user, logOut } = useAccount()

  const image = user?.photo?.url

  return (
    <div className={s.container}>
      <Dropdown.Root>
        <Dropdown.Trigger asChild className={s.trigger}>
          <div className={s.toggleContainer}>
            <Avatar name={`${user?.firstName} ${user?.lastName}`} src={image} />
          </div>
        </Dropdown.Trigger>
        <Dropdown.Content align="start" loop>
          <Dropdown.Item
            onClick={() => navigate(Routes.routes.SETTING.create({}))}
          >
            Settings
          </Dropdown.Item>
          <Dropdown.Item onClick={logOut}>Log out</Dropdown.Item>
        </Dropdown.Content>
      </Dropdown.Root>
    </div>
  )
}
