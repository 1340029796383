import React, { createContext, useState, useContext, useEffect } from 'react'
import { useApolloClient, useLazyQuery, useMutation } from '@quipu/apollo'

import { localStorageKeys } from '../constants'
import { BaseUserFragment } from '@quipu/graphql'
import {
  GetUserDocument,
  LoginDocument,
  RegisterDocument,
  RegisterInput,
} from '@quipu/graphql/src/typemapImports'
import { getParsedItem, setItem } from '@quipu/utils'

interface AccountContextProps {
  user?: BaseUserFragment | null
  isAuthenticated: boolean
  refreshAuthenticationCheck: () => void
  logIn: (email: string, password: string) => void
  register: (data: RegisterInput) => void
  logOut: () => void
}

export const AccountContext = createContext<AccountContextProps | undefined>(
  undefined
)

export const AccountProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [isInitialized, setIsInitialized] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem('accessToken')
  )

  const client = useApolloClient()
  const [loginMutation] = useMutation(LoginDocument)
  const [registerMutation] = useMutation(RegisterDocument)

  const refreshAuthenticationCheck = () => {
    setIsAuthenticated(
      Boolean(localStorage.getItem('accessToken') && getParsedItem('user'))
    )
  }

  const logOut = () => {
    setIsAuthenticated(false)
    localStorage.removeItem(localStorageKeys.accessToken)
    localStorage.removeItem(localStorageKeys.user)
    client.resetStore()
  }

  const [getUser, { data: userData }] = useLazyQuery(GetUserDocument, {
    onCompleted: data => {
      setItem('user', JSON.stringify(data.user))
    },
    onError: () => {
      logOut()
    },
  })

  const register = async (data: RegisterInput) => {
    try {
      const { data: registerData } = await registerMutation({
        variables: {
          input: data,
        },
      })

      localStorage.removeItem(localStorageKeys.activeWorkspace)
      setItem('accessToken', registerData?.register.jwt!)
      setItem('user', JSON.stringify(registerData?.register.user))

      setIsAuthenticated(true)

      return data
    } catch (error) {
      console.error(error)
    }
  }

  const logIn = async (email: string, password: string) => {
    try {
      const { data } = await loginMutation({
        variables: {
          email,
          password,
        },
      })
      setItem('accessToken', data?.login.jwt!)

      await getUser({
        variables: { filters: { id: data?.login.user.id } },
      })

      setIsAuthenticated(true)

      return data
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isInitialized) {
      const init = async () => {
        await getUser()
        setIsInitialized(true)
      }
      init()
    }
  }, [isInitialized, getUser])

  return (
    <AccountContext.Provider
      value={{
        user: userData?.user || null,
        isAuthenticated,
        refreshAuthenticationCheck,
        logIn,
        logOut,
        register,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

export const useAccount = () => {
  const context = useContext(AccountContext)

  if (context === undefined) {
    throw new Error('useAccount must be used within a AccountContext')
  }

  return context
}
