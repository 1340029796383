import React from 'react'
import * as s from './Allocation.styles'
import { Card, cs } from '@quipu/components'

interface AllocationProps {
  color: string
  name: string
  allocatedAmount?: number
  spentAmount: number
  remainingAmount?: number
}

export const Allocation = ({
  color,
  name,
  allocatedAmount,
  spentAmount,
  remainingAmount,
}: AllocationProps) => {
  return (
    <Card className={s.card}>
      <div
        className={cs(
          s.categoryColor,
          !allocatedAmount && s.categoryColorCentered
        )}
        style={{ backgroundColor: color }}
      >
        &nbsp;
      </div>

      <div className={s.innerContainer}>
        <div className={s.innerContainerRow}>
          <div className={s.categoryName}>{name}</div>
          <div className={s.amount}>{spentAmount} €</div>
        </div>

        {allocatedAmount && remainingAmount ? (
          <div className={s.innerContainerRow}>
            <div className={s.remainingAmount}>
              Remaining: {remainingAmount} €
            </div>
            <div className={s.limitAmount}>Limit: {allocatedAmount} €</div>
          </div>
        ) : null}
      </div>
    </Card>
  )
}
