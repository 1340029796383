import React from 'react'

type RefTypeOf<T, Fallback = HTMLDivElement> = T extends React.ComponentType<
  React.RefAttributes<infer R>
>
  ? R
  : Fallback

export const alterProps = <Wrapped extends React.ComponentType<any>>(
  WrappedComponent: Wrapped
) => {
  const alter = <InputProps extends {} = React.ComponentProps<Wrapped>>(
    propsMap: (input: InputProps) => React.ComponentProps<Wrapped>
  ) => {
    const Altered = React.forwardRef<RefTypeOf<Wrapped>, InputProps>(
      (props, ref) => (
        <WrappedComponent ref={ref} {...(propsMap(props) as any)} />
      )
    )

    const displayName =
      WrappedComponent.displayName || WrappedComponent.name || 'alterProps'
    Altered.displayName = displayName
    return Altered
  }

  return alter
}

export type AlterPropFn<
  Comp extends React.ComponentType<any>,
  NewProps extends {} = React.ComponentProps<Comp>,
> = (props: NewProps) => React.ComponentProps<Comp>
