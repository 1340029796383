import React from 'react'
import { Link } from '@quipu/components'
import * as styles from './NotFoundView.styles'
import { Routes } from '@quipu/modules-common'

export const NotFoundView = () => {
  return (
    <div className={styles.container}>
      <svg
        width="300"
        height="300"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" />
        <circle
          cx="10"
          cy="10"
          r="4"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
        />
        <line
          x1="13"
          y1="13"
          x2="16"
          y2="16"
          stroke="currentColor"
          strokeWidth="2"
        />
        <circle cx="10" cy="9" r="0.5" fill="currentColor" />
        <circle cx="12" cy="9" r="0.5" fill="currentColor" />
        <path
          d="M9.5 11.5 Q11 13 12.5 11.5"
          stroke="currentColor"
          strokeWidth="1.5"
          fill="none"
        />
      </svg>

      <h1>Page Not Found</h1>
      <p>The page you’re looking for doesn’t exist.</p>
      <Link href={Routes.routes.ROOT.create({})}>Go Home</Link>
    </div>
  )
}
