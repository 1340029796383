import React from 'react'
import { DatePicker, Grid, Input, Modal } from '@quipu/components'
import moment from 'moment'

export interface ProjectFormValues {
  name: string
  categoryId?: string
  tags?: string[]
  date: string
  description?: string | null
}

export const ProjectFormContent = () => {
  return (
    <>
      <Modal.PaddedContent top>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Input.FormField
              name="name"
              label="Name"
              type="text"
              required
              autoFocus
            />
          </Grid>
          <Grid xs={12}>
            <Input.FormField
              name="description"
              label="Description"
              placeholder="Additional notes"
            />
          </Grid>
          {/*
          // TODO: Add support to categories in projects
          <Grid xs={6}>
            <CategoryField
              name="categoryId"
              label="Category"
              onCreateNew={category => setFieldValue('category', category?.id!)}
            />
          </Grid>
            */}
          <Grid xs={6}>
            <Input.CustomFormField label="Date" name="date" required>
              {methods => (
                <>
                  <DatePicker
                    value={methods.watch('date')}
                    onChange={date => {
                      methods.setValue(
                        'date',
                        moment(date).format('YYYY-MM-DD')
                      )
                      methods.trigger('date')
                    }}
                    // TODO: Review these extra props
                    excludeScrollbar={true}
                    onSelect={() => {}}
                  />
                </>
              )}
            </Input.CustomFormField>
          </Grid>
        </Grid>
      </Modal.PaddedContent>
    </>
  )
}
