import { css } from '@linaria/core'
import { colors } from '@quipu/style-foundations'

export const largeTitle = css`
  font-size: 4.8rem;
  font-weight: 600;
  color: ${colors.black};

  .dark & {
    color: ${colors.white85};
  }
`

export const heading1 = css`
  font-size: 3.2rem;
  font-weight: 600;
  color: ${colors.black};

  .dark & {
    color: ${colors.white85};
  }
`

export const heading2 = css`
  font-size: 2.4rem;
  font-weight: 400;
  color: ${colors.black};

  .dark & {
    color: ${colors.white55};
  }
`

export const heading3 = css`
  font-size: 2rem;
  font-weight: 400;
  color: ${colors.black};

  .dark & {
    color: ${colors.white25};
  }
`

export const subtitle = css`
  font-size: 1.6rem;
  font-weight: 600;
  color: ${colors.black};

  .dark & {
    color: ${colors.white55};
  }
`

export const body = css`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${colors.black};
  line-height: 1.5;

  .dark & {
    color: ${colors.white85};
  }
`

export const footnote = css`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${colors.black};

  .dark & {
    color: ${colors.white85};
  }
`

export const caption = css`
  font-size: 1.2rem;
  font-weight: 400;
  color: ${colors.black85};

  .dark & {
    color: ${colors.white85};
  }
`
