import { css } from '@linaria/core'
import { colors } from '@quipu/style-foundations'

export const card = css`
  display: flex;
  min-width: 31.7rem;
  height: 6.2rem;
  align-items: center;
  cursor: pointer;
  padding: 1.4rem 1.6rem;
  margin-bottom: 2.4rem;

  :hover {
    background: hsl(180, 29%, 94%);
    cursor: pointer;
  }
`

export const innerContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const innerContainerRow = css`
  display: flex;
  justify-content: space-between;
`

export const categoryColor = css`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.8rem;
`

export const categoryColorCentered = css`
  align-self: center;
`

export const categoryName = css`
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  color: ${colors.black};
`

export const amount = css`
  font-size: 14px;
  font-weight: 600;
  color: #2c2c2c;
`

export const remainingAmount = css`
  font-size: 11px;
  font-weight: 500;
  color: ${colors.black};
`

export const limitAmount = css`
  font-size: 11px;
  font-weight: 600;
  color: hsl(0, 100%, 61%);
`
