import React from 'react'
import {
  FieldValues,
  Grid,
  Input,
  Modal,
  UseFormReturn,
} from '@quipu/components'
import { CategoryField } from '../../../../components/CategoryField'

export interface BudgetAllocationFormValues {
  allocatedAmount: string
  categoryId: string
  budgetId: string
}

interface BudgetAllocationFormProps<TFieldValues extends FieldValues>
  extends UseFormReturn<TFieldValues> {}

export const BudgetAllocationForm = (
  methods: BudgetAllocationFormProps<BudgetAllocationFormValues>
) => {
  return (
    <Modal.PaddedContent top>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <CategoryField
            name="categoryId"
            label="Category"
            value={methods.watch('categoryId')}
            onChange={option =>
              methods.setValue(
                'categoryId',
                !Array.isArray(option) ? option?.value : undefined
              )
            }
          />
        </Grid>
        <Grid xs={12}>
          {/* TODO: Create a price field */}
          <Input.FormField
            name="allocatedAmount"
            label="Amount"
            required
            placeholder="0,00"
          />
        </Grid>
      </Grid>
    </Modal.PaddedContent>
  )
}
