import React from 'react'

import { Primitive } from '../Primitives'
import { useInputContext } from './inputContext'
import { setRefs } from '../../utils'

export interface InputRef extends Primitive.InputRef {}
export interface InputProps extends OmitSafe<Primitive.InputProps, 'size'> {}

export const InputBase = React.forwardRef<InputRef, InputProps>(
  (props, ref) => {
    const { ...rest }: typeof props = props
    const { id, forward } = useInputContext()

    const { ref: contextRef, ...forwardRest } = forward || {}

    const passDown = { ...(forwardRest || {}), ...rest }

    return (
      <Primitive.input
        ref={r => setRefs(r, ref, contextRef)}
        id={id || undefined}
        {...passDown}
      />
    )
  }
)
InputBase.displayName = 'InputBase'
