import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import { breakpoint, colors } from '@quipu/style-foundations'

export const filtersRelativeContainer = css`
  position: relative;
  width: 100%;
`

export const FiltersAbsoluteContainer = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;

  background: ${colors.white};

  transition: all 400ms linear;

  ${breakpoint('xs')} {
    width: 100%;
    /* Apply this only on desktop, on mobile it's applied directly on the input field */
    border: 1px solid ${colors.black20};
    border-radius: 8px;
  }

  box-shadow: ${props =>
    props.open
      ? `0 5px 5px -3px hsla(0, 0%, 0%, 0.16),
  0 8px 10px 1px hsla(0, 0%, 0%, 0.05),
  0 3px 14px 2px hsla(0, 0%, 0%, 0.08)`
      : 'none'};
`

export const Menu = styled.div`
  display: flex;
  width: 100%;
  background: ${colors.white};
  z-index: 1;

  border-top: 1px solid ${colors.black20};
  padding: 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  button {
    margin-right: 16px;
  }
`
