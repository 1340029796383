import { clsx } from 'clsx'

// TODO: experiment generating these classes on a runtime and injecting them to a <style> tag on the fly.
// import './placing.styles.css'
// import './spacing.styles.css'

// Placing and Spacing classes are defined in inspire-foundations package
// Each app includes them when importing from '@quipu/style-foundations/styles.css'
import { PlacingGlobalClasses } from './placing.types'
import { SpacingGlobalClasses } from './spacing.types'

export type GlobalClasses = PlacingGlobalClasses | SpacingGlobalClasses

export type GlobalCxParam = GlobalClasses | { [key in GlobalClasses]?: boolean }

const global = (
  firstClass: GlobalCxParam,
  ...classes: GlobalCxParam[]
): string => {
  return clsx(firstClass, ...classes)
}

const csBase: typeof clsx = (...args) => clsx(...args)

export const cs = Object.assign(csBase, { global })
