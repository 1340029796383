import React from 'react'
import { Form, Modal, useForm, yup, yupResolver } from '@quipu/components'
import { useMutation } from '@quipu/apollo'
import { CreateBudgetAllocationDocument } from '@quipu/graphql'
import {
  BudgetAllocationForm,
  BudgetAllocationFormValues,
} from './BudgetAllocationForm'

const schema = yup.object().shape({
  categoryId: yup.string().required(),
  budgetId: yup.string().required(),
  allocatedAmount: yup.string().required(),
})

const initialValues: BudgetAllocationFormValues = {
  categoryId: '',
  budgetId: '',
  allocatedAmount: '',
}

interface CreateBudgetAllocationFormProps {
  budgetId: string
}

export const CreateBudgetAllocationForm = ({
  budgetId,
}: CreateBudgetAllocationFormProps) => {
  const [createBudgetAllocation] = useMutation(CreateBudgetAllocationDocument)

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: { ...initialValues, budgetId },
  })

  const handleSubmit = async (values: typeof initialValues) => {
    createBudgetAllocation({
      variables: {
        input: {
          ...values,
          allocatedAmount: parseFloat(values.allocatedAmount),
        },
      },
    })
  }

  return (
    <Modal.Card>
      <Form {...methods} onSubmit={handleSubmit}>
        <Modal.PaddedContent all>
          <Modal.Title>Create Budget Allocation</Modal.Title>

          <BudgetAllocationForm {...methods} />
        </Modal.PaddedContent>

        <Modal.Footer>
          <Modal.Close />
          <Modal.Submit>Add</Modal.Submit>
        </Modal.Footer>
      </Form>
    </Modal.Card>
  )
}
