import { styled } from '@linaria/react'
import React, { FC, useEffect, useRef, useState } from 'react'

const ANIMATION_TIME = 1000

const loadingAnimationBefore = `
  @keyframes loadingAnimationBefore {
    0% {
      transform: translate3d(0, 0, 0);
    }

    50% {
      transform: translate3d(-100%, 0, 0);
    }

    100% {
      transform: translate3d(0, 0, 0);
    }
  }
`

const loadingAnimation = `
  @keyframes loadingAnimation {
    0% {
      transform: translate3d(-50%, 0, 0);
    }

    50% {
      transform: translate3d(0, 0, 0);
    }

    100% {
      transform: translate3d(-50%, 0, 0);
    }
  }
`

type Size = '16px' | '24px' | '64px'

export interface LoadingPlaceholderProps {
  size?: Size
  primary?: boolean
  /* dark prop is used to force a light spinner (for prototypes for example) */
  dark?: boolean
  className?: string
}

const BAR_HEIGHT = {
  '16px': '4px',
  '24px': '6px',
  '64px': '8px',
} as const

const INNER_BAR_WIDTH = {
  '16px': '8px',
  '24px': '10px',
  '64px': '20px',
}

interface LoadingAnimationWrapperProps extends LoadingPlaceholderProps {
  'data-testid': string
  $mountDelay: number
  $primary?: boolean
  $dark?: boolean
  $size: Size
}

const BarWrapper = styled.span`
  width: 100%;
  border-radius: 999px;
  overflow: hidden;

  /* This is needed to fix a safari bug with border-radius
  https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b */
  /* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  /* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */
`

const Bar = styled.span<{ $size: Size }>`
  ${loadingAnimation}
  ${loadingAnimationBefore}
  width: 100%;
  display: block;
  height: 1em;
  position: relative;

  padding-right: 100%;
  padding-left: 100%;
  transform: translate3d(-50%, 0, 0);
  animation: loadingAnimation ${ANIMATION_TIME}ms linear infinite;

  ::before {
    width: 100%;
    position: absolute;

    left: 0;
    top: 0;

    content: '';
    display: inline-block;
    height: 1em;
    opacity: 0.15;

    background: currentcolor;
  }

  ::after {
    border-radius: 999px;
    width: ${({ $size }) => INNER_BAR_WIDTH[$size]};

    content: '';
    display: inline-block;
    height: 1em;
    background: currentcolor;

    animation: loadingAnimationBefore ${ANIMATION_TIME}ms linear infinite;
  }
`

const LoadingAnimationComponent = styled.span<LoadingAnimationWrapperProps>`
  display: inline-flex;
  width: ${({ $size }) => $size};
  font-size: ${({ $size }) => BAR_HEIGHT[$size]};
  aspect-ratio: 1;

  line-height: 1;

  align-items: center;

  overflow: hidden;

  color: ${({ $dark, $primary }) =>
    $dark
      ? 'white'
      : $primary
      ? 'hsla(19, 89%, 55%, 0.7)'
      : 'hsla(0, 0%, 0%, 0.55)'};

  ${Bar} {
    animation-delay: ${({ $mountDelay }) => $mountDelay}ms;

    ::after {
      animation-delay: ${({ $mountDelay }) => $mountDelay}ms;
    }
  }
`

const LoadingPlaceholder: FC<LoadingPlaceholderProps> = ({
  className,
  size = '24px',
  primary = false,
  dark = false,
  ...props
}) => {
  /**
   * We capture when this component was mounted. This way we can apply a delay
   * to the animation based on that time and make all Loading animations in the app to be
   * in sync.
   *
   * Imagine animation lasts 100ms:
   *
   * 0                 100ms     150ms    200ms
   * | ----------------- | ----------------- |
   *                               |
   *                             mount
   *
   * We configure the animation to start on the previous cycle: 100ms, so we
   * set the animation-delay to -50ms. Finally, we use negative numbers to make
   * the component animate right away (instead of waiting for the next cycle).
   */
  const mountTime = React.useRef(Date.now())
  const mountDelay = -(mountTime.current % ANIMATION_TIME)

  return (
    <LoadingAnimationComponent
      className={className}
      data-testid="loading-animation"
      $primary={primary}
      $dark={dark}
      $mountDelay={mountDelay}
      $size={size}
      {...props}
    >
      <BarWrapper>
        <Bar $size={size} />
      </BarWrapper>
    </LoadingAnimationComponent>
  )
}

export interface LoadingProps extends LoadingPlaceholderProps {
  /**
   * Do not render the component for given amount of milliseconds.
   * This is useful in high paced workflows, where loading animation could be shown such a brief moment
   * that it could look like more like flickering than actual loading indicator.
   */
  delayMs?: number
}

export const Loading = (props: LoadingProps) => {
  const { delayMs, ...rest } = props
  const [hideLoading, setHideLoading] = useState(true)

  // Use refs to pass down props to useEffect.
  // Doing this we avoid disabling eslint rule
  const refs = useRef({ delayMs, setHideLoading })
  refs.current.setHideLoading = setHideLoading

  useEffect(() => {
    const { delayMs, setHideLoading } = refs.current

    if (!delayMs || delayMs <= 0) {
      setHideLoading(false)
      return
    }

    const timeout = setTimeout(() => {
      setHideLoading(false)
    }, delayMs)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  if (hideLoading) {
    return null
  }

  return <LoadingPlaceholder {...rest} />
}
