import React from 'react'
import moment from 'moment'
import { Card, cs } from '@quipu/components'
import PlusIcon from '@quipu/icons/assets/plus-16.svg?react'
import MinusIcon from '@quipu/icons/assets/minus-16.svg?react'
import * as s from './OverviewGridItem.styles'

interface OverviewGridItem {
  id: string
  name: string
  date?: string
  income: number
  expense: number
  onClick: (id: string) => void
}

export const OverviewGridItem = ({
  id,
  name,
  date,
  income,
  expense,
  onClick,
}: React.PropsWithChildren<OverviewGridItem>) => {
  const formatAmount = (amount: number) =>
    amount > 0 ? amount.toFixed(2) : amount

  return (
    <Card className={s.card} onClick={() => onClick(id)}>
      <div className={s.name}>{name}</div>
      {date ? (
        <div className={s.date}>
          Started {moment(date).format('MMMM d, YYYY')}
        </div>
      ) : null}
      <div className={s.incomeExpenseWrapper}>
        <div className={s.incomeExpenseContainer}>
          <div className={cs(s.title, s.expenseTitle)}>
            Expenses <MinusIcon />
          </div>
          <div className={s.textContainer}>{formatAmount(expense)} €</div>
        </div>

        <div className={s.separator}></div>

        <div className={s.incomeExpenseContainer}>
          <div className={cs(s.title, s.incomeTitle)}>
            Income <PlusIcon />
          </div>
          <div className={s.textContainer}>{formatAmount(income)} €</div>
        </div>
      </div>
    </Card>
  )
}
