import React, { FC } from 'react'

import { Button, FormProvider, Grid, useModal } from '@quipu/components'
import { useMutation, useQuery } from '@quipu/apollo'
import {
  CategoryFragment,
  DeleteCategoryDocument,
  GetCategoriesDocument,
} from '@quipu/graphql'
import { CategoryList, CategoryModal } from './components'

export const WorkspaceSettingsCategoriesView: FC = () => {
  const { showModal } = useModal()

  // TODO: Replace this localStorage.getItem with a custom one
  const activeWorkspaceId = localStorage.getItem('activeWorkspace')!

  // Queries
  const { data } = useQuery(GetCategoriesDocument, {
    variables: {
      workspaceId: activeWorkspaceId,
    },
  })

  // Mutations
  const [deleteCategory] = useMutation(DeleteCategoryDocument, {
    refetchQueries: [
      {
        query: GetCategoriesDocument,
        variables: {
          workspaceId: activeWorkspaceId,
        },
      },
    ],
  })

  const handleAddCategory = () => {
    const dismissModal = showModal({
      element: <CategoryModal dismissModal={() => dismissModal()} />,
    })
  }

  const handleEditCategory = (item: CategoryFragment) => {
    const dismissModal = showModal({
      element: (
        <CategoryModal edit {...item} dismissModal={() => dismissModal()} />
      ),
    })
  }

  const handleDeleteCategory = (id: string) => {
    deleteCategory({
      variables: {
        id,
      },
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Button onClick={handleAddCategory}>Add category</Button>
      </Grid>

      <Grid xs={12}>
        <CategoryList
          items={data?.categories.entries}
          onEditClick={handleEditCategory}
          onDeleteClick={handleDeleteCategory}
        />
      </Grid>
    </Grid>
  )
}
