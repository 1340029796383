import { Operation } from '@apollo/client'

function searchForFiles(item: any, files: File[]) {
  if (item instanceof File) {
    files.push(item)
    return
  }

  if (!item || typeof item !== 'object') return

  for (const key in item) {
    const keyValue = item?.[key]
    if (keyValue && typeof keyValue === 'object') {
      searchForFiles(item[key], files)
    }
  }
}
function findFilesInObject(obj: unknown): File[] {
  const files: File[] = []
  searchForFiles(obj, files)
  return files
}

export const isOperationWithFiles = (operation: Operation): boolean => {
  const files = findFilesInObject(operation.variables)
  return files.length > 0
}
