import React from 'react'

import { ManageModals, ModalData } from './useModalsState'
import { ModalInjectedProps } from './ModalsMountContext'
import { DefaultModalMount } from './DefaultModalMount'

export const renderModal = (modal: ModalData, manageModals: ManageModals) => {
  const injectedModalProps: ModalInjectedProps = {
    dismissModal: () => manageModals.removeModal(modal.id),
  }

  const {
    element,
    component,
    mount: Mount = DefaultModalMount,
    mountProps = {},
  }: typeof modal = modal

  const content = (
    <>
      {component ? (
        <component.comp {...component.props} {...injectedModalProps} />
      ) : null}
      {element && <>{element}</>}
    </>
  )

  return (
    <Mount
      key={`modal-${modal.id}`}
      open={modal.open}
      modal
      onOpenChange={() => manageModals.removeModal(modal.id)}
      {...mountProps}
    >
      {content}
    </Mount>
  )
}
