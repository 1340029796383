import React, { useEffect, useState } from 'react'
import { useNavigate, matchPath, useLocation, Navigate } from 'react-router-dom'
import { ApolloError, useQuery } from '@quipu/apollo'

import {
  Button,
  Grid,
  Form,
  Input,
  Link,
  useForm,
  yup,
  yupResolver,
} from '@quipu/components'
import { Routes } from '@quipu/modules-common'
import { useAccount } from '../../context'

import * as S from './SignInView.styles'
import { VerifyWorkspaceInvitationDocument } from '@quipu/graphql'

const schema = yup.object().shape({
  email: yup
    .string()
    // default email validation is too permissible, this one requires at least one @ and domain
    .matches(/.+@.+\..+/, 'Please enter a valid email address')
    .required('Email is required'),
})

const initialFormData = {
  email: '',
  password: '',
}

export const SignInView = () => {
  const { isAuthenticated, logIn, logOut } = useAccount()
  const { state } = useLocation()
  const navigate = useNavigate()

  const isFromAcceptInvitation = state?.from?.pathname
    ? matchPath(
        state?.from?.pathname,
        Routes.routes.WORKSPACE_ACCEPT_INVITATION.template
      )
    : false

  const { data: invitationData, loading: invitationDataLoading } = useQuery(
    VerifyWorkspaceInvitationDocument,
    {
      variables: {
        token: String(
          isFromAcceptInvitation && isFromAcceptInvitation.params.token
        ),
      },
      skip: !isFromAcceptInvitation,
    }
  )

  useEffect(() => {
    if (isFromAcceptInvitation) {
      logOut()
    }
  }, [isFromAcceptInvitation, logOut])

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const initialFormValues = isFromAcceptInvitation
    ? {
        ...initialFormData,
        email: invitationData?.verifyWorkspaceInvitation?.email!,
      }
    : undefined

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: initialFormValues,
  })

  const submit = async (data: any) => {
    try {
      setLoading(true)

      await logIn(data.email, data.password)

      if (state?.from) {
        navigate(state.from.pathname)
      } else {
        navigate(Routes.routes.ROOT.create({}))
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        setError(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  if (isAuthenticated) {
    return <Navigate to={Routes.routes.ROOT.create({})} />
  }

  let title = ''
  if (isFromAcceptInvitation) {
    title = `You have been invited to join "${invitationData?.verifyWorkspaceInvitation?.workspace?.name}" workspace.`
  }

  return (
    <S.Container>
      <S.FormContainer>
        <S.Logo />
        <S.Title>{title}</S.Title>
        <S.SubTitle>
          To get started, sign in or{' '}
          <Link
            size={18}
            href={Routes.routes.SIGN_UP.create({})}
            onClick={event => {
              event.preventDefault()
              navigate(Routes.routes.SIGN_UP.create({}), {
                state,
              })
            }}
          >
            create an account
          </Link>
          .
        </S.SubTitle>

        {invitationDataLoading ? (
          <>Loading...</>
        ) : (
          <Form {...methods} onSubmit={submit}>
            <Grid gap="2">
              <div>
                <Input.FormField
                  name="email"
                  label="Email"
                  required
                  readOnly={Boolean(isFromAcceptInvitation)}
                  disabled={Boolean(isFromAcceptInvitation)}
                  autoFocus
                />
              </div>
              <div>
                <Input.FormField
                  type="password"
                  name="password"
                  label="Password"
                  required
                />
              </div>

              {error && (
                <div>
                  <S.Error>{error}</S.Error>
                </div>
              )}
              <div>
                <Button type="submit" disabled={loading} fullWidth>
                  Sign In
                </Button>
              </div>
            </Grid>
          </Form>
        )}
      </S.FormContainer>
    </S.Container>
  )
}
