import { GraphqlProvider } from '@quipu/apollo'

import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import {
  DarkModeProvider,
  ModalsProvider,
  ToastProvider,
} from '@quipu/components'
import { AccountProvider } from '../context'

const SubProviders = (props: React.PropsWithChildren) => {
  return (
    <DarkModeProvider>
      <GraphqlProvider
        config={{
          uri:
            process.env.VITE_APP_GRAPHQL_API_URL ||
            'http://localhost:8080/graphql',
        }}
      >
        <ToastProvider>
          <AccountProvider>
            <ModalsProvider>{props.children}</ModalsProvider>
          </AccountProvider>
        </ToastProvider>
      </GraphqlProvider>
    </DarkModeProvider>
  )
}

export const Providers = (props: React.PropsWithChildren) => {
  return (
    <BrowserRouter>
      <SubProviders>{props.children}</SubProviders>
    </BrowserRouter>
  )
}
