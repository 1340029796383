import React from 'react'

export const setRefs = (
  ref: HTMLElement | null,
  ...refObjs: (React.Ref<HTMLElement> | undefined)[]
) => {
  for (const refObj of refObjs) {
    if (typeof refObj === 'function') refObj(ref)
    else if (refObj) (refObj as any).current = ref
  }
}
