import { colors } from '@quipu/style-foundations/src'
import { styled } from '@linaria/react'

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1.6rem;
  background-color: ${colors.cultured55};
  border-top: 1px solid ${colors.black5};

  > * + * {
    margin-left: 1.6rem;
  }
`
