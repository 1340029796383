import React, { useState } from 'react'
import moment from 'moment'
import * as styles from './ProjectTotals.styles'
import { Select } from '@quipu/components'
import Chevron from '@quipu/icons/assets/chevrons-down-16.svg?react'

interface ProjectTotalsProps {
  totalExpense: number
  totalIncome: number
  onChange: (value?: [string, string]) => void
}

type FilterOption = 'all' | 'currentMonth' | 'lastMonth'

export const ProjectTotals = ({
  totalExpense,
  totalIncome,
  onChange,
}: ProjectTotalsProps) => {
  const [selectedOption, setSelectedOption] = useState<FilterOption>('all')

  const handleSelectedOption = (option: FilterOption) => {
    console.log(option)
    setSelectedOption(option)

    switch (option) {
      case 'all':
        onChange()
        break
      case 'currentMonth':
        onChange([
          moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
          moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        ])
        break
      case 'lastMonth':
        onChange([
          moment()
            .subtract(1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD HH:mm:ss'),
          moment()
            .subtract(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD HH:mm:ss'),
        ])
        break
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.filterContainer}>
        <div className={styles.filterTitle}>Balance</div>
        <div className={styles.dropdownFilterContent}>
          {/* TODO: Implement these filters on BE */}
          <Select.Root
            value={selectedOption}
            onValueChange={handleSelectedOption}
          >
            <Select.Trigger className={styles.dropdownToggle}>
              <span>{selectedOption}</span>
              <Chevron />
            </Select.Trigger>
            <Select.Content>
              <Select.Item value="all">All time</Select.Item>
              <Select.Item value="currentMonth">Current month</Select.Item>
              <Select.Item value="lastMonth">Last month</Select.Item>
            </Select.Content>
          </Select.Root>
        </div>
      </div>
      <div className={styles.totalContainer}>
        <div className={styles.totalTitle}>Spent</div>
        <div className={styles.totalValueSpent}>
          {totalExpense.toFixed(2)} €
        </div>
      </div>
      <div className={styles.totalContainer}>
        <div className={styles.totalTitle}>Income</div>
        <div className={styles.totalValueIncome}>
          {totalIncome.toFixed(2)} €
        </div>
      </div>
    </div>
  )
}
