import React, { Dispatch, SetStateAction, useLayoutEffect, useRef } from 'react'
import { CSSProperties } from '@linaria/core'
import { cs } from '../../utils'
import * as ToastPrimitive from '@radix-ui/react-toast'

import {
  root,
  toastIcon,
  toastTitle,
  toastDescription,
  toastContent,
  toastClose,
  neutral,
  positive,
  negative,
} from './Toast.styles'

const CLASS_FOR_VARIANT = {
  neutral,
  positive,
  negative,
}

const getElementHeight = (containerRef: React.RefObject<HTMLLIElement>) =>
  `${containerRef.current!.getBoundingClientRect().height}px`

export type ToastVariants = 'neutral' | 'positive' | 'negative'

export interface ToastProps {
  /* Used in `removeToast` to remove toasts from the array*/
  id: string
  description: React.ReactNode | string

  title?: string
  variant?: ToastVariants
  action?: {
    /* For accessibility purposes: https://www.radix-ui.com/primitives/docs/components/toast#action */
    altText: string
    /* The component to be rendered, usually a <Button /> */
    component: React.ReactNode
  }
  /* Renders a button to close the toaster */
  withClose?: boolean
  open?: boolean

  /**
   * For accessibility purposes (screen readers)
   * [From Radix Docs ]:
   *  "For toasts that are the result of a user action, choose "foreground".
   *   Toasts generated from background tasks should use "background"."
   */
  type?: ToastPrimitive.ToastImplProps['type']
}

interface ToastComponentProps extends ToastProps {
  onOpenChange: Dispatch<SetStateAction<boolean>>
}

const VARIANT_ICON: Record<ToastVariants, React.ReactNode> = {
  neutral: 'a',
  positive: 'b',
  negative: 'c',
}

/**
 * Toast
 *
 * The Toast component renders the container of the Toast itself
 * and is responsible for the styles of the container.
 *
 * Can always be dismissable by swipping right
 */
export const Toast: React.FC<ToastComponentProps> = ({
  title,
  description,
  action,
  onOpenChange,
  open = true,
  withClose = true,
  variant = 'neutral',
  type = 'foreground',
}) => {
  const containerRef = useRef<ToastPrimitive.ToastImplElement>(null)
  const elementHeight = useRef<string>('')

  // Calculate element height so we can have a smooth hiding transition
  // when closing a toaster in a stack
  useLayoutEffect(() => {
    if (containerRef.current) {
      elementHeight.current = getElementHeight(containerRef)
    }
  }, [])

  const variantStyle = CLASS_FOR_VARIANT[variant]
  const rootStyle: CSSProperties = {
    '--height': elementHeight.current,
  }

  return (
    <ToastPrimitive.Root
      ref={containerRef}
      style={rootStyle}
      className={root}
      onOpenChange={onOpenChange}
      open={open}
      type={type}
    >
      <div className={toastContent}>
        <div className={cs(toastIcon, variantStyle)}>
          {VARIANT_ICON[variant]}
        </div>

        <div>
          {title && (
            <ToastPrimitive.Title className={toastTitle}>
              {title}
            </ToastPrimitive.Title>
          )}
          <ToastPrimitive.Description className={toastDescription}>
            {description}
          </ToastPrimitive.Description>
        </div>
        {action && (
          <div>
            <ToastPrimitive.Action asChild altText={action.altText}>
              {action.component}
            </ToastPrimitive.Action>
          </div>
        )}
      </div>

      {withClose && (
        <ToastPrimitive.Close aria-label="Close" className={toastClose}>
          <span aria-hidden>×</span>
        </ToastPrimitive.Close>
      )}
    </ToastPrimitive.Root>
  )
}
