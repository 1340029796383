import { Title as DialogTitle } from '@radix-ui/react-dialog'
import React, { PropsWithChildren } from 'react'

import { Text } from '../../Text'

export interface TitleProps extends PropsWithChildren {}

export const Title = (props: TitleProps) => {
  const { children } = props
  return (
    <DialogTitle asChild>
      <Text type="heading2">{children}</Text>
    </DialogTitle>
  )
}
