import { useMediaQuery } from 'react-responsive'

export const BREAKPOINTS_IN_PX = {
  /*
  Because `styled-components-breakpoint` works with GTE values,
  we should have the smallest width possible.

  So we can make mobile only CSS like

  breakpoint('base', 'xs')`
    background-color: blue;
  `
  */
  base: 0,
  xxs: 325,
  xs: 512,
  sm: 768,
  md: 1024,
  lg: 1200,
  xl: 1600,
  xxl: 2000,
  xxxl: 2300,
}

type Breakpoints = keyof typeof BREAKPOINTS_IN_PX

export const breakpoint = (minSize: Breakpoints, maxSize?: Breakpoints) => {
  const min = BREAKPOINTS_IN_PX[minSize]

  if (maxSize) {
    const max = BREAKPOINTS_IN_PX[maxSize] - 1
    return `@media (min-width: ${min}px) and (max-width: ${max}px)`
  }

  return `@media (min-width: ${min}px)`
}

export const useBreakpoint = (on: Breakpoints) =>
  useMediaQuery({ minWidth: BREAKPOINTS_IN_PX[on] })
