import { css } from '@linaria/core'
import { colors } from '@quipu/style-foundations/src'

export const inputContainer = css`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`

export const inputIcon = css`
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  left: 1.2rem;
  color: ${colors.black55};
`

export const inputText = css`
  width: 100%;
  padding-left: 4.4rem;
  background: none;
  border: 0;
  outline: 0;

  :hover,
  :focus {
    border: 0;
  }

  &:not([readonly]):hover {
    outline: 0;
  }
`
