import React, { ReactNode } from 'react'

import { DropdownRoot } from '../DropdownRoot'
import { DropdownTrigger } from '../DropdownTrigger'
import { DropdownContent, DropdownContentProps } from '../DropdownContent'
import { Size } from '../Dropdown.types'

export interface DropdownDefaultProps extends DropdownContentProps {
  trigger: ReactNode
  size?: Size
}

export const DropdownDefault = (props: DropdownDefaultProps) => {
  const { trigger, children, size, ...rest } = props
  return (
    <DropdownRoot>
      <DropdownTrigger size={size}>{trigger}</DropdownTrigger>
      <DropdownContent {...rest}>{children}</DropdownContent>
    </DropdownRoot>
  )
}
