import React from 'react'

import * as styles from './ProjectInfo.styles'
import { Grid, Pill, useModal, Link } from '@quipu/components'
import { BaseProjectDetailsFragment } from '@quipu/graphql'
import { UpdateProjectForm } from '../ProjectForm/UpdateProjectForm'

interface ProjectInfoProps {
  id: string
  name: string
  category: BaseProjectDetailsFragment['category']
}

export const ProjectInfo = ({ id, name, category }: ProjectInfoProps) => {
  const { showModal } = useModal()

  const handleViewProjectDetails = () => {
    const dismissModal = showModal({
      element: (
        <UpdateProjectForm id={id} dismissModal={() => dismissModal()} />
      ),
    })
  }

  return (
    <Grid container>
      <Grid xs={12}>
        <div className={styles.parentExpenseHeaderContainer}>
          <h1>{name}</h1>

          {category?.name ? (
            <Pill color={category?.color!}>{category?.name!}</Pill>
          ) : null}
        </div>
        <Link onClick={handleViewProjectDetails}>
          <small>View Project Details</small>
        </Link>
      </Grid>
    </Grid>
  )
}
