/**
 * Copy & pasted from Radix Primitives
 * https://github.com/radix-ui/primitives/blob/c31c97274ff357aea99afe6c01c1c8c58b6356e0/packages/react/primitive/src/Primitive.tsx
 *
 * These primitives support `asChild` property for our own components.
 *
 * ---
 *
 * Radix primitives are exposed to the world by `@radix-ui/primitive`
 * But it is also said, that that package is only for internal `@radix-ui` use
 * and they don't give any guarantees that the package won't change at any time.
 *
 * In addition to that, `@radix-ui/primitive` does not have primitives for all HTML
 * tags that we need (e.g. h1 is missing)
 *
 * So we simply copy & pasted them here to be sure nothing unexpected happens.
 *
 * And finally - the extra overhead added is quite small.
 */
import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import {
  PrimitiveForwardRefComponent,
  PrimitivePropsWithRef,
} from './helperTypes'

/* -------------------------------------------------------------------------------------------------
 * Primitive
 * -----------------------------------------------------------------------------------------------*/

const createPrimitive = <E extends React.ElementType>(node: E) => {
  const Node = React.forwardRef(
    (props: PrimitivePropsWithRef<typeof node>, forwardedRef: any) => {
      const { asChild, ...primitiveProps } = props
      const Comp: any = asChild ? Slot : node

      React.useEffect(() => {
        // eslint-disable-next-line no-extra-semi
        ;(window as any)[Symbol.for('radix-ui')] = true
      }, [])

      return <Comp {...primitiveProps} ref={forwardedRef} />
    }
  ) as PrimitiveForwardRefComponent<E>

  Node.displayName = `Primitive.${node}`
  return Node
}

export const a = createPrimitive('a')
export const button = createPrimitive('button')
export const div = createPrimitive('div')
export const form = createPrimitive('form')
export const h1 = createPrimitive('h1')
export const h2 = createPrimitive('h2')
export const h3 = createPrimitive('h3')
export const img = createPrimitive('img')
export const input = createPrimitive('input')
export const label = createPrimitive('label')
export const li = createPrimitive('li')
export const ul = createPrimitive('ul')
export const nav = createPrimitive('nav')
export const ol = createPrimitive('ol')
export const p = createPrimitive('p')
export const span = createPrimitive('span')
export const svg = createPrimitive('svg')
export const textarea = createPrimitive('textarea')
