import React, { useEffect, useMemo, useState } from 'react'
import { Input } from '../Input/Input'
import { inputContainer, inputIcon, inputText } from './SearchInput.styles'
import debounce from 'debounce'
import SearchIcon from '@quipu/icons/assets/search-24.svg?react'

interface SearchInputProps {
  value?: string
  placeholder?: string
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  inputRef: React.RefObject<HTMLInputElement>
}

export const SearchInput = ({
  value,
  onChange,
  inputRef,
  ...props
}: SearchInputProps) => {
  const [stateValue, setStateValue] = useState(value)

  useEffect(() => {
    setStateValue(value)
  }, [value])

  const debouncedOnChange = useMemo(() => debounce(onChange, 500), [onChange])

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value
    setStateValue(newValue)
    debouncedOnChange(event)
  }

  return (
    <div className={inputContainer}>
      <SearchIcon className={inputIcon} />
      <Input
        {...props}
        ref={inputRef}
        className={inputText}
        value={stateValue}
        onChange={handleSearch}
      />
    </div>
  )
}
