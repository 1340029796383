import React from 'react'
import {
  DatePicker,
  FieldValues,
  Grid,
  Input,
  Modal,
  Select,
  UseFormReturn,
  yup,
} from '@quipu/components'
import moment from 'moment'

export const budgetFormSchema = yup.object().shape({
  startDate: yup.string().required(),
  endDate: yup.string().required(),
  totalAmount: yup.string().required(),
  projectId: yup.string().required(),
  recurrent: yup.boolean().required(),
})

export interface BudgetFormValues {
  totalAmount: string
  startDate: string
  endDate: string
  recurrent: boolean
  projectId: string
}

interface BudgetFormProps<TFieldValues extends FieldValues>
  extends UseFormReturn<TFieldValues> {}

type Periodicity = 'weekly' | 'monthly' | 'yearly' | 'custom'

export const BudgetForm = ({ setValue }: BudgetFormProps<BudgetFormValues>) => {
  const [selectedPeriodicity, setSelectedPeriodicity] =
    React.useState<Periodicity>()

  const handlePeriodicityChange = (value: Periodicity) => {
    setSelectedPeriodicity(value)

    const startDate = moment().format('YYYY-MM-DD HH:mm:ss')
    let endDate
    setValue('startDate', startDate)

    switch (value) {
      case 'weekly': {
        endDate = moment().add(1, 'weeks').format('YYYY-MM-DD HH:mm:ss')
        break
      }
      case 'monthly': {
        endDate = moment().add(1, 'months').format('YYYY-MM-DD HH:mm:ss')
        break
      }
      case 'yearly': {
        endDate = moment().add(1, 'years').format('YYYY-MM-DD HH:mm:ss')
        break
      }
    }

    if (endDate) setValue('endDate', endDate)
  }

  return (
    <Modal.PaddedContent top>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Input.FormField
            name="totalAmount"
            label="Total amount"
            placeholder="0,00"
            required
          />
        </Grid>
        <Grid xs={12}>
          <Input.CustomFormField label="Periodicity" name="periodicity">
            <Select.RootDefault onValueChange={handlePeriodicityChange}>
              <Select.Item value="weekly">Weekly</Select.Item>
              <Select.Item value="monthly">Monthly</Select.Item>
              <Select.Item value="yearly">Yearly</Select.Item>
              <Select.Item value="custom">Custom</Select.Item>
            </Select.RootDefault>
          </Input.CustomFormField>
        </Grid>
        {selectedPeriodicity === 'custom' && (
          <>
            <Grid xs={12} sm={6}>
              <Input.CustomFormField
                label="Start date"
                name="startDate"
                required
              >
                {methods => (
                  <DatePicker
                    selected={methods.watch('startDate')}
                    startDate={methods.watch('startDate')}
                    endDate={methods.watch('endDate')}
                    selectsStart
                    onChange={date => {
                      if (!date) return

                      methods.setValue('startDate', date.toISOString())
                    }}
                    // TODO: Review these extra props
                    excludeScrollbar={true}
                    onSelect={() => {}}
                  />
                )}
              </Input.CustomFormField>
            </Grid>
            <Grid xs={12} sm={6}>
              <Input.CustomFormField label="End date" name="endDate" required>
                {methods => (
                  <DatePicker
                    selected={methods.watch('endDate')}
                    startDate={methods.watch('startDate')}
                    endDate={methods.watch('endDate')}
                    selectsEnd
                    onChange={date => {
                      if (!date) return

                      methods.setValue('endDate', date.toISOString())
                    }}
                    // TODO: Review these extra props
                    excludeScrollbar={true}
                    onSelect={() => {}}
                  />
                )}
              </Input.CustomFormField>
            </Grid>
          </>
        )}
      </Grid>
    </Modal.PaddedContent>
  )
}
