import { css } from '@linaria/core'

export const layoutContainerStyles = css`
  /* These need to be override because of the box-shadows on the list items  */
  width: calc(100% + 16px);
  margin-left: -8px;
  margin-top: -16px;
  padding: 1.6rem 0.8rem;
`

export const projectInfoContainer = css`
  display: flex;
  justify-content: space-between;
`
