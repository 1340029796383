import { cs } from '../../utils'
import React from 'react'

import * as textStyles from './Text.styles'
import { colors } from '@quipu/style-foundations'
import { Primitive } from '../Primitives'
import { PrimitiveForwardRefComponent } from '../Primitives/helperTypes'

type Styles = keyof typeof textStyles

export interface TextProps extends React.PropsWithChildren {
  type?: Styles
  color?: keyof typeof colors
  className?: string
  style?: React.CSSProperties
}

type ElementType = HTMLHeadingElement | HTMLParagraphElement

const STYLE_TO_ELEMENT_MAPPING: Record<
  Styles,
  PrimitiveForwardRefComponent<any>
> = {
  largeTitle: Primitive.h1,
  heading1: Primitive.h1,
  heading2: Primitive.h2,
  heading3: Primitive.h3,
  subtitle: Primitive.h2,
  body: Primitive.p,
  footnote: Primitive.span,
  caption: Primitive.span,
}

export const Text = React.forwardRef<ElementType, TextProps>(function Text(
  props,
  ref
) {
  const {
    className,
    type = 'body',
    children,
    style = {},
    color,
    ...otherProps
  } = props

  const customStyles: React.CSSProperties = { ...style }
  if (color) {
    customStyles.color = colors[color] as string
  }

  return React.createElement(
    STYLE_TO_ELEMENT_MAPPING[type],
    {
      className: cs(textStyles[type], className),
      ref,
      style: customStyles,
      ...otherProps,
    },
    children
  )
})
