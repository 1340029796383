import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'

import { buildCache } from './cache'

// Links
import { createAuthLink, createConnectionLink } from './links'

export interface ClientConfiguration {
  uri: string
  cache?: InMemoryCache
}

export const buildClient = (config: ClientConfiguration) => {
  const { cache = buildCache() }: typeof config = config

  const link = ApolloLink.from([
    createAuthLink(),
    createConnectionLink(config.uri),
  ])

  return new ApolloClient({ cache, link })
}
