import React from 'react'

import { Input } from '../Input'
import { InputRoot } from '../InputRoot'
import { InputLabel } from '../InputLabel'
import { InputError } from '../InputError'
import { useFormContext } from 'react-hook-form'

import { Primitive } from '../../Primitives'
import { TextareaBase } from '../Textarea'
import { cs } from '../../../utils'

interface BaseInputFormFieldProps {
  name: string
  label: string
  showErrors?: boolean
  placeholder?: string
}

interface InputFormFieldProps
  extends BaseInputFormFieldProps,
    Omit<React.ComponentProps<typeof Input>, 'name'> {
  type?: Primitive.InputProps['type']
}

interface TextareaFormFieldProps
  extends BaseInputFormFieldProps,
    Omit<React.ComponentProps<typeof TextareaBase>, 'name'> {
  type: 'textarea'
}

type FormFieldProps = InputFormFieldProps | TextareaFormFieldProps

export const FormField = (props: FormFieldProps) => {
  const { name, label, showErrors = false, placeholder, type, value } = props
  const { register, formState } = useFormContext()

  return (
    <InputRoot name={name} formState={formState} register={register}>
      <InputLabel>{label}</InputLabel>
      {type === 'textarea' ? (
        <TextareaBase
          className={cs.global('mt-4')}
          placeholder={placeholder}
          value={value}
        />
      ) : (
        <Input
          className={cs.global('mt-4')}
          type={type}
          placeholder={placeholder}
          value={value}
        />
      )}

      {showErrors ? <InputError /> : null}
    </InputRoot>
  )
}
