import React, { createContext, useMemo, useState } from 'react'
import * as ToastPrimitive from '@radix-ui/react-toast'

import { viewport } from './Toast.styles'
import { Toast, ToastProps } from './Toast'

interface ToastProviderProps {
  children: React.ReactNode
}

const DURATION = 5000
export const HIDE_ANIMATION_DURATION = 250

/**
 * MultipleToast
 *
 * This context will give us"addToast" and "removeToast"
 * which are methods implemented in the ToastProvider and
 * used in the "useToast" hook.
 *
 * These methods will add and remove "Toasts" to an array
 * allowing us to render multiple toasts if needed
 */
export const MultipleToasts = createContext({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  addToast: (toasts: ToastProps) => {},
  removeToast: (toasts: ToastProps, timeout?: boolean) => {},
  /* eslint-enable @typescript-eslint/no-unused-vars */
})

/**
 * ToastProvider
 *
 * Provides add and remove methods and manages an array of toats so we can
 * render a stack of toasts
 */
export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([])

  const manageToasts = useMemo(() => {
    const handleAddToast = (toast: ToastProps) =>
      setToasts(prev => [...prev, toast])

    const handleRemoveToast = (props: ToastProps) => {
      // Update the toast "open" prop to false so it runs the animation
      setToasts(toasts =>
        toasts.reduce((prev: ToastProps[], curr: ToastProps) => {
          const closingToast =
            curr.id === props.id
              ? {
                  ...props,
                  open: false, // Triggers animations
                }
              : curr

          const updatedToasts = [...prev, closingToast]
          return updatedToasts
        }, [])
      )

      // Actually remove the toast from the array
      setTimeout(() => {
        setToasts(toasts => toasts.filter(toast => toast.id !== props.id))
      }, HIDE_ANIMATION_DURATION + 50) // Wait a bit so the animation plays
    }

    return {
      addToast: handleAddToast,
      removeToast: handleRemoveToast,
    }
  }, [])

  return (
    <MultipleToasts.Provider value={manageToasts}>
      <ToastPrimitive.Provider swipeDirection="right" duration={DURATION}>
        {children}
        {toasts.map(props => (
          <Toast
            key={props.id}
            onOpenChange={() => manageToasts.removeToast(props)}
            {...props}
          />
        ))}
        <ToastPrimitive.Viewport className={viewport} />
      </ToastPrimitive.Provider>
    </MultipleToasts.Provider>
  )
}
