import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { content, overlay } from './DefaultModalMount.styles'

export type ModalMountProps = Dialog.DialogProps

/**
 * ******************************************************
 *                      WARNING!
 *                  READ BEFORE EDIT
 * ******************************************************
 *
 * Radix components use special logic under the hood such as:
 * - `React.children.map` - to manipulate children element
 * - `@radix-ui/react-presence` to detect if component is being unmounted
 *   (this one also requires that components would spread props and forward ref)
 * - `@radix-ui/react-slot` and `asChild` prop to pass down props to children
 *
 * That being said - it is extremely easy to break this component.
 * - If you'll add any intermediate component - likely something will break
 * - If you'll wrap any of the component without spreading props and forwarding
 *   ref - likely something will break
 *
 * If you are certain that you want to edit the default component - please make sure
 * that you've read:
 * - [Radix's composition guide](https://www.radix-ui.com/primitives/docs/guides/composition#composing-with-your-own-react-components)
 * - [Check how Radix achieves animations](https://github.com/radix-ui/primitives/blob/c31c97274ff357aea99afe6c01c1c8c58b6356e0/packages/react/presence/src/Presence.tsx)
 *
 * And lastly - after this scary warning - what is really doable:
 *  - you can wrap the whole `DefaultModalMount` with your own component
 *  - you can forward props to one (or more) of the dialog components
 *  - if you need something really custom - you can copy&paste the whole
 *    structure `DefaultModalMount`, edit it and pass it down to `showModal` function.
 */
export const DefaultModalMount = (props: Dialog.DialogProps) => {
  const { children, ...rest } = props

  return (
    <Dialog.Root {...rest}>
      <Dialog.Portal>
        <Dialog.Overlay className={overlay} />
        {/* Radix passes pointerEvents: auto as a style, so we need to override it here */}
        <Dialog.Content className={content} style={{ pointerEvents: 'none' }}>
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
