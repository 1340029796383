import { DialogProps } from '@radix-ui/react-dialog'
import { AddModalProps, AddModalReturn } from './ModalsMountContext'
import { useMemo, useState } from 'react'
import { uniqueId } from '@quipu/utils'
import { HIDE_ANIMATION_DURATION } from './DefaultModalMount.styles'

export interface ModalData extends AddModalProps<any> {
  id: string
  open: boolean
  mount?: React.ComponentType<DialogProps>
  mountProps?: Record<string, any>
}

export type ManageModals = ReturnType<typeof useModalsState>['manageModals']
export const useModalsState = () => {
  const [modals, setModals] = useState<ModalData[]>([])

  const manageModals = useMemo(() => {
    const handleAddModal = (props: AddModalProps): AddModalReturn => {
      const { mount, ...rest }: typeof props = props
      const id = uniqueId()

      const newModal: ModalData = { mount, id, open: true, ...rest }
      setModals(prev => [...prev, newModal])

      return { id }
    }

    const handelRemoveModal = (id: string) => {
      // Update the modal "open" prop to false so it runs the animation
      setModals(modals => {
        const closingModal = modals.find(modal => modal.id === id)
        if (!closingModal) return modals
        closingModal.open = false
        return [...modals]
      })

      setTimeout(() => {
        setModals(modals => modals.filter(modal => modal.id !== id))
      }, HIDE_ANIMATION_DURATION + 50) // Wait a bit so the animation plays
    }

    return { addModal: handleAddModal, removeModal: handelRemoveModal }
  }, [])

  return { modals, manageModals }
}
