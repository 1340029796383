import React, { forwardRef } from 'react'
import RawDatePicker, { DatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import * as styles from './Datepicker.styles'
import * as inputStyles from '../Input/Input.styles'
import { cs } from '../../utils'

const customInput = (
  {
    value,
    placeholder,
    onClick,
    onChange,
  }: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  ref: React.ForwardedRef<HTMLInputElement>
) => (
  <input
    autoComplete="off"
    className={cs(inputStyles.inputCss, inputStyles.sizeMedium)}
    value={value}
    ref={ref}
    onClick={onClick}
    onChange={onChange}
    placeholder={placeholder}
  />
)

const CustomInput = forwardRef(customInput)

export const DatePicker = ({ startDate, ...props }: DatePickerProps) => {
  const selectedDate = startDate ? new Date(startDate) : undefined

  return (
    <RawDatePicker
      {...props}
      dateFormat="yyyy/MM/dd"
      selected={props.selected || selectedDate}
      startDate={selectedDate}
      customInput={<CustomInput />}
      calendarClassName={styles.calendar}
      wrapperClassName={styles.wrapper}
    />
  )
}
