import React, { FC } from 'react'
import moment from 'moment'

import * as s from './TransactionsList.styles'
import { BaseTransactionDetailsFragment } from '@quipu/graphql'
import { useBreakpoint } from '@quipu/style-foundations'
import { Dropdown, Pill } from '@quipu/components'

interface ListProps {
  items?: BaseTransactionDetailsFragment[]

  maxHeight?: string
  clickable?: boolean
  canPinExpenses?: boolean

  onClick?: (item: BaseTransactionDetailsFragment) => void
  onEditClick?: (item: BaseTransactionDetailsFragment) => void
  onDeleteClick: (id: string) => void
  onPinClick?: (item: BaseTransactionDetailsFragment) => void
  onConvertExpenseClick?: (item: BaseTransactionDetailsFragment) => void
  onFetchMore?: (nextPage: number) => void
  placeholders?: JSX.Element[]
}

export const TransactionsList: FC<ListProps> = ({
  items,

  maxHeight,
  clickable = true,
  canPinExpenses = true,

  onDeleteClick,
  onClick,
  onEditClick,
  onPinClick,
  onConvertExpenseClick,

  placeholders,
}) => {
  const isMobile = !useBreakpoint('md')

  const handleRowClick = (item: BaseTransactionDetailsFragment) => {
    clickable && onClick && onClick(item)
  }

  const getActionMenuOptions = (item: BaseTransactionDetailsFragment) => {
    const options = [
      <Dropdown.Item
        key="delete-transaction"
        onClick={() => onDeleteClick(item.id)}
      >
        Delete
      </Dropdown.Item>,
    ]

    if (onEditClick) {
      options.unshift(
        <Dropdown.Item key="edit-transaction" onClick={() => onEditClick(item)}>
          Edit
        </Dropdown.Item>
      )
    }

    if (canPinExpenses) {
      options.unshift(
        <Dropdown.Item key="pin-transaction" onClick={() => onPinClick?.(item)}>
          {item.pinned ? 'Unpin' : 'Pin'}
        </Dropdown.Item>
      )
    }

    if (onConvertExpenseClick) {
      options.push(
        <Dropdown.Item
          key="convert-transaction"
          onClick={() => onConvertExpenseClick(item)}
        >
          Convert into group
        </Dropdown.Item>
      )
    }

    return options
  }

  const renderItemsForDesktop = (item: BaseTransactionDetailsFragment) => (
    <>
      <s.Column onClick={() => handleRowClick(item)} width="40%">
        {item.name}
      </s.Column>
      <s.Column onClick={() => handleRowClick(item)} width="20%">
        {moment(item.date).format('DD-MM-YYYY')}
      </s.Column>
      <s.Column onClick={() => handleRowClick(item)} width="18%">
        {item.category?.name && item.category?.color ? (
          <Pill color={item.category?.color}>{item.category?.name}</Pill>
        ) : null}
      </s.Column>
      <s.Column onClick={() => handleRowClick(item)} width="14%">
        {item.tags?.map(tag => (
          <Pill key={tag.id} variant="secondary">
            {tag.name}
          </Pill>
        ))}
      </s.Column>
      <s.Column onClick={() => handleRowClick(item)} width="14%">
        {item.amount?.toFixed(2)} €
      </s.Column>
      <s.ActionsMenuColumn width="50px">
        <Dropdown.Default trigger={<>•••</>}>
          {...getActionMenuOptions(item)}
        </Dropdown.Default>
      </s.ActionsMenuColumn>
    </>
  )

  const renderItemsForMobile = (item: BaseTransactionDetailsFragment) => (
    <>
      <s.Column flexDirection="column">
        <s.Column onClick={() => handleRowClick(item)} width="100%">
          {item.name}
        </s.Column>

        <s.Column width="100%">
          <s.Column width="75%" flexDirection="column">
            <s.Column onClick={() => handleRowClick(item)} width="100%">
              {moment(item.date).format('DD-MM-YYYY')}
            </s.Column>
            {item.category?.name && item.category?.color ? (
              <s.Column onClick={() => handleRowClick(item)} width="100%">
                <Pill color={item.category?.color}>{item.category?.name}</Pill>
              </s.Column>
            ) : null}
            {item.tags?.length ? (
              <s.Column onClick={() => handleRowClick(item)} width="100%">
                {item.tags?.map(tag => (
                  <Pill key={tag.id} variant="secondary">
                    {tag.name}
                  </Pill>
                ))}
              </s.Column>
            ) : null}
          </s.Column>

          <s.AmountColumnMobile>
            <s.Column onClick={() => handleRowClick(item)} width="100%">
              {item.amount?.toFixed(2)} €
            </s.Column>
            <s.ActionsMenuColumn>
              <Dropdown.Default trigger={<>•••</>}>
                {...getActionMenuOptions(item)}
              </Dropdown.Default>
            </s.ActionsMenuColumn>
          </s.AmountColumnMobile>
        </s.Column>
      </s.Column>
    </>
  )

  return (
    <s.Container id="transaction-list" maxHeight={maxHeight}>
      {items?.map(item => (
        <s.Row key={item.id} clickable={clickable} expenseGroup={false}>
          {isMobile ? renderItemsForMobile(item) : renderItemsForDesktop(item)}
        </s.Row>
      ))}

      {items?.length ? placeholders : null}
    </s.Container>
  )
}
