import * as styles from './Card.styles'
import { alterProps, cs } from '../../utils'
import { Primitive } from '../Primitives'

interface CardProps extends Primitive.DivProps {
  borderStyle?: 'solid' | 'dotted'
  variant?: 'primary' | 'secondary'
  clickable?: boolean
}

export const Card = alterProps(Primitive.div)<CardProps>(props => {
  const {
    className,
    children,
    variant = 'primary',
    borderStyle,
    clickable = true,
    ...rest
  }: typeof props = props

  return {
    className: cs(
      styles.container,
      variant === 'primary' ? styles.variantPrimary : undefined,
      borderStyle
        ? borderStyle === 'solid'
          ? styles.solidBorder
          : styles.dottedBorder
        : undefined,
      clickable && styles.clickableContainer,
      className
    ),
    ...rest,
    children,
  }
})
