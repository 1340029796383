import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@quipu/apollo'
import {
  FormProvider,
  Input,
  Select,
  useForm,
  yup,
  yupResolver,
} from '@quipu/components'
import { CreateTagDocument, GetTagsDocument } from '@quipu/graphql'

interface Option {
  value: string
  label: string
  color: string
}

interface TagFieldProps {
  name: string
  label: string
  value?: string[]
  onCreate?: () => void
  onChange?: (options: Option | Option[]) => void
}

const schema = yup.object().shape({
  value: yup.string().required(),
})

export const TagField = ({
  label,
  name,
  value: externalValue,
  onCreate,
  onChange,
}: TagFieldProps) => {
  // TODO: Replace this localStorage.getItem with a custom one
  const workspaceId = localStorage.getItem('activeWorkspace')!
  const [value, setValue] = useState<Option[] | undefined>()
  const [options, setOptions] = useState<Option[]>([])

  const { refetch } = useQuery(GetTagsDocument)

  const [createTag] = useMutation(CreateTagDocument, {
    refetchQueries: [
      {
        query: GetTagsDocument,
        variables: {
          workspaceId,
        },
      },
    ],
    onCompleted: onCreate,
  })

  const loadOptions = async (searchValue: string) => {
    const result = await refetch({
      filters: {
        workspaceId,
        name: searchValue,
      },
    })

    const results = result.data.tags.entries.map(tag => {
      return {
        value: tag.id,
        label: tag.name,
        // TODO: Add support for tags to have colors
        color: '#000',
      }
    })

    setOptions(results)
    return results
  }

  useEffect(() => {
    if (externalValue) {
      setValue(
        options.filter(option => externalValue.includes(option.value)) || []
      )
    } else {
      setValue(undefined)
    }
  }, [externalValue, options])

  const handleCreate = ({ value }: { value: string }) => {
    createTag({
      variables: {
        input: {
          workspace: workspaceId,
          name: value,
        },
      },
    })
  }

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  })

  return (
    <FormProvider {...methods}>
      <Input.CustomFormField label={label} name={name}>
        <Select.SearchAsync
          loadOptions={loadOptions}
          multi
          onCreateNew={handleCreate}
          onChange={onChange}
          value={value}
        />
      </Input.CustomFormField>
    </FormProvider>
  )
}
