import React, { useEffect, useState } from 'react'
import {
  Navigate,
  Outlet,
  Routes,
  useLocation,
  useMatch,
} from 'react-router-dom'
import { Routes as AppRoutes } from '@quipu/modules-common'
import { useAccount } from '../context'
import { ProjectLayout } from '../components'
import {
  SignInView,
  SignUpView,
  OverviewView,
  TransactionsView,
  ProjectTransactionsView,
  ProjectBudgetView,
  NotFoundView,
  WorkspaceSettingsGeneralView,
  WorkspaceSettingsCategoriesView,
} from '../views'

const Route = AppRoutes.Route

const Layout = () => {
  return <Outlet />
}

const AuthGuard = () => {
  const location = useLocation()
  const { isAuthenticated, user } = useAccount()
  const isEntryRoute = useMatch({ path: AppRoutes.routes.ROOT.template })

  const [workspaceId, setWorkspaceId] = useState(() =>
    localStorage.getItem('activeWorkspace')
  )

  useEffect(() => {
    if (!workspaceId && user?.personalWorkspace?.id) {
      const id = user.personalWorkspace.id
      localStorage.setItem('activeWorkspace', id)
      setWorkspaceId(id)
    }
  }, [user, workspaceId])

  if (!isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: AppRoutes.routes.SIGN_IN.create({}),
        }}
        state={{ from: location }}
      />
    )
  }

  if (isEntryRoute && workspaceId) {
    return (
      <Navigate
        to={{ pathname: AppRoutes.routes.OVERVIEW.create({ workspaceId }) }}
      />
    )
  }

  return <Outlet />
}

const Router = () => {
  return (
    <Routes>
      <Route path="/sign-in" element={<SignInView />} />
      <Route path="/sign-up" element={<SignUpView />} />

      <Route element={<AuthGuard />}>
        <Route path="/:workspaceId/t" element={<TransactionsView />} />

        <Route element={<Layout />}>
          <Route path="/:workspaceId" element={<OverviewView />} />

          <Route
            path="/:workspaceId/settings"
            element={<WorkspaceSettingsGeneralView />}
          />
          <Route
            path="/:workspaceId/settings/categories"
            element={<WorkspaceSettingsCategoriesView />}
          />
        </Route>

        <Route element={<ProjectLayout />}>
          <Route
            path="/:workspaceId/p/:projectId"
            element={<ProjectTransactionsView />}
          />
          <Route
            path="/:workspaceId/p/:projectId/budget"
            element={<ProjectBudgetView />}
          />
        </Route>

        <Route path="/*" element={<NotFoundView />} />
      </Route>
    </Routes>
  )
}

export default Router
