import { DialogProps } from '@radix-ui/react-dialog'
import { createContext } from 'react'

/**
 * ModalContext
 *
 * Returns "addModal" and "removeModal" which allows us to manage the
 * modals array in the useModal hook
 */
export interface ModalMountContextProps {
  addModal(props: AddModalProps<any, any>): AddModalReturn
  removeModal(id: string): void
}

export interface AddModalProps<
  T extends {} = {},
  M extends DialogProps = DialogProps,
> {
  component?: { comp: React.ComponentType<T & ModalInjectedProps>; props?: T }
  element?: React.ReactNode
  mount?: React.ComponentType<M>
  mountProps?: Record<string, any>
}

export interface AddModalReturn {
  id: string
}

export interface ModalInjectedProps {
  dismissModal: () => void
}

export const ModalsMountContext = createContext<ModalMountContextProps | null>(
  null
)
