import { css } from '@linaria/core'
import { breakpoint } from '@quipu/style-foundations'

export const mainContainer = css`
  display: flex;
  width: 100%;
  height: 100%;

  ${breakpoint('base', 'sm')} {
    flex-direction: column;
  }
`

export const contentContainer = css`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  overflow: hidden;
  max-width: 1200px;

  padding: 60px 80px;

  ${breakpoint('base', 'sm')} {
    padding: 24px 16px;
    margin-top: 4rem;
  }
`

export const header = css`
  display: flex;
  margin-bottom: 56px;
  justify-content: space-between;

  ${breakpoint('base', 'sm')} {
    margin-bottom: 56px;
  }
`

export const container = css`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  /*
  Transactions list component has a shadow in each row, we need to give an extra
  margin to all element within this container, except for the transaction-list
  to avoid cutting these shadows.
  */
  & > *:not(#transaction-list) {
      margin-left: 0.8rem;
      margin-right: 0.8rem;
    }
  }
`

export const alignCenter = css`
  align-items: center;
`
