import { css } from '@linaria/core'
import { colors } from '@quipu/style-foundations'

export const container = css`
  display: flex;
  align-items: center;
`

export const trigger = css`
  display: flex;
  align-items: center;
  align-self: center;
  background: none;
  cursor: pointer;
`

export const toggleContainer = css`
  display: flex;
  height: 4.4rem;
  width: 4.4rem;
  align-self: center;
`

export const optionItemActive = css`
  color: ${colors.green};
  background: ${colors.green10};
`

export const optionSeparator = css`
  width: 100%;
  height: 1px;
  background: ${colors.black10};
  margin: 0.8rem 0;
`
