import { css } from '@linaria/core'
import { colors, breakpoint } from '@quipu/style-foundations'

export const card = css`
  display: flex;
  flex-direction: column;
  min-width: 32rem;
  height: 13.8rem;
  padding: 1.6rem;
  display: flex;

  ${breakpoint('xs')} {
    width: 100%;
  }
`

export const name = css`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;
  color: hsl(0, 0%, 17%);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const date = css`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${colors.black40};
`

export const incomeExpenseWrapper = css`
  display: flex;
  margin-top: auto;
  justify-content: space-between;
`

export const incomeExpenseContainer = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

export const separator = css`
  display: flex;
  width: 0.1rem;
  height: 100%;
  background: ${colors.black};
  margin: 0 1.2rem;
`

export const title = css`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 0.4rem;
  }
`

export const expenseTitle = css`
  color: hsl(0, 100%, 50%);
`

export const incomeTitle = css`
  color: hsl(180, 81%, 29%);
`

export const textContainer = css`
  display: flex;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
`
