import React from 'react'

import * as styles from './CategoryList.styles'
import { CategoryFragment } from '@quipu/graphql'
import { TagField } from '../../../../components/TagField'
import { Dropdown } from '@quipu/components'

interface CategoryListProps {
  items?: CategoryFragment[]
  onClick?: (id: string) => void
  onEditClick: (item: CategoryFragment) => void
  onDeleteClick: (id: string) => void
}

export const CategoryList = ({
  items,
  onDeleteClick,
  onClick,
  onEditClick,
}: CategoryListProps) => {
  const handleRowClick = (id: string) => {
    onClick?.(id)
  }

  return (
    <div className={styles.column}>
      {items?.map(item => (
        <div key={item.id}>
          <div
            className={styles.column}
            onClick={() => handleRowClick(item.id!)}
          >
            <TagField
              label={item?.name!}
              name={item?.name!}
              //  color={item?.color!}
            />
          </div>

          <Dropdown.Default trigger="...">
            <Dropdown.Item onClick={() => onEditClick(item)}>
              Edit…
            </Dropdown.Item>
            <Dropdown.Item onClick={() => onDeleteClick(item.id)}>
              Delete…
            </Dropdown.Item>
          </Dropdown.Default>
        </div>
      ))}
    </div>
  )
}
