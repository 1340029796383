import { inputCss, sizeSmall, sizeMedium } from './Input.styles'
import { alterProps, cs } from '../../utils'
import { Primitive } from '../Primitives'
import { InputBase } from './InputBase'

const SIZE_STYLES = {
  small: sizeSmall,
  medium: sizeMedium,
}

type Size = 'small' | 'medium'

interface InputProps extends OmitSafe<Primitive.InputProps, 'size'> {
  /**
   * If true - it will add a default spacing (margin) styling
   */
  spacing?: boolean
  /**
   * Size medium by default
   */
  size?: Size
}

export const Input = alterProps(InputBase)<InputProps>(props => {
  const { spacing, className, size = 'medium', ...rest } = props

  return {
    className: cs(
      className,
      inputCss,
      cs.global('fill-x', { 'mt-8': spacing }),
      SIZE_STYLES[size as Size]
    ),
    ...rest,
  }
})
