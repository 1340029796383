import { styled } from '@linaria/react'
import { colors } from '@quipu/style-foundations/src'

import { Primitive } from '../../Primitives'

export const ModalCard = styled(Primitive.div)<{ maxWidth?: string }>`
  pointer-events: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  min-width: 30%;
  max-width: ${props => props.maxWidth || '52rem'};

  background-color: ${colors.white};

  border-radius: 1.6rem;

  // TODO: Add this shadow as a variable
  box-shadow: 0 5px 10px 0 hsla(0, 0%, 0%, 0.1),
    0 15px 40px 0 hsla(0, 0%, 0%, 0.15);

  &:focus {
    outline: none;
  }
`
