/* eslint-disable */

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    WorkspaceEntry: ['WorkspaceInvitation', 'WorkspaceMember'],
  },
}
export default result
