import React, { PropsWithChildren } from 'react'
import * as Select from '@radix-ui/react-select'
import Chevron from '@quipu/icons/assets/chevrons-down-16.svg?react'
import Checked from '@quipu/icons/assets/checked-16.svg?react'
import {
  SelectTrigger,
  SelectIcon,
  SelectContent,
  SelectItemStyles,
  SelectItemText,
  IconIndicator,
  Container,
  IconContainer,
  errorCss,
} from './Select.styles'
import { cs } from '../../utils'

interface SelectDefaultRootProps extends Select.SelectProps {
  placeholder?: string
  className?: string
  position?: 'popper' | 'item-aligned'
}

export const Root: React.FC<Select.SelectProps> = ({ children, ...props }) => {
  return <Select.Root {...props}>{children}</Select.Root>
}

export const Trigger: React.FC<Select.SelectTriggerProps> = ({
  placeholder,
  className,
  ...props
}) => {
  return (
    <Select.Trigger className={cs(SelectTrigger, className)} {...props}>
      <Select.Value placeholder={placeholder} />
      <Select.Icon className={SelectIcon}>
        <Chevron width="16px" />
      </Select.Icon>
    </Select.Trigger>
  )
}

export const RootDefault: React.FC<SelectDefaultRootProps> = ({
  children,
  position = 'popper',
  className,
  ...props
}) => {
  return (
    <Select.Root {...props}>
      <Trigger placeholder={props.placeholder} className={className} />
      <Select.Portal>
        <Select.Content className={SelectContent} position={position}>
          <Select.Viewport>
            <Select.Group>{children}</Select.Group>
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  )
}

export const Content: React.FC<Select.SelectContentProps> = ({
  children,
  position = 'item-aligned',
}) => {
  return (
    <Select.Portal>
      <Select.Content className={SelectContent} position={position}>
        <Select.Viewport>
          <Select.Group>{children}</Select.Group>
        </Select.Viewport>
      </Select.Content>
    </Select.Portal>
  )
}

export const Item: React.FC<Select.SelectItemProps> = ({
  children,
  ...props
}) => {
  return (
    <Select.Item
      className={SelectItemStyles}
      data-testid={props.value}
      {...props}
    >
      <Container>
        <IconContainer>
          <Select.ItemIndicator className={IconIndicator}>
            <Checked />
          </Select.ItemIndicator>
        </IconContainer>
        <Select.ItemText className={SelectItemText}>{children}</Select.ItemText>
      </Container>
    </Select.Item>
  )
}

export const Error: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className={errorCss}>{children}</div>
}
