import React from 'react'
import * as s from './Grid.styles'
import { CSSProperties } from '@linaria/core'

interface GridContainerProps {
  spacing?: number
}

interface GridItemProps {
  xs: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
}

export const GridContainer = ({
  children,
  spacing = 1,
}: React.PropsWithChildren<GridContainerProps>) => {
  const style: CSSProperties = {
    '--spacing': `${spacing * 8}px`,
  } as CSSProperties

  return (
    <div className={s.container} style={style}>
      {children}
    </div>
  )
}

export const GridItem = ({
  children,
  xs,
  sm,
}: React.PropsWithChildren<GridItemProps>) => {
  const classNames = [s.item, `xs-${xs}`, sm ? `sm-${sm}` : ''].join(' ')
  return <div className={classNames}>{children}</div>
}

type GridPropsForContainer = { container: true } & GridContainerProps
type GridPropsForItem = { container?: false } & GridItemProps

type GridProps = GridPropsForContainer | GridPropsForItem

export const Grid = (props: React.PropsWithChildren<GridProps>) => {
  if (props.container) {
    return <GridContainer {...(props as GridPropsForContainer)} />
  }

  return <GridItem {...(props as GridPropsForItem)} />
}
