import { styled } from '@linaria/react'

import { colors } from '@quipu/style-foundations/src'

export const Container = styled.div<{ editable?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-shrink: 0;

  width: 100%;
  height: 100%;

  overflow: hidden;
  user-select: none;
`

export const Border = styled.div`
  width: 100%;
  height: 100%;

  padding: 0.4rem;
  border: 2px dashed ${colors.black10};
  border-radius: 50%;
`

export const NoImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;

  background-color: ${colors.cultured55};
  color: ${colors.black85};
  border: none;
  border-radius: 50%;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;

  border-radius: 50%;
`
