import { css } from '@linaria/core'
import { colors } from '@quipu/style-foundations'

export const trigger = css`
  display: flex;
  cursor: pointer;
  background: ${colors.black5};
  color: ${colors.black50};
  font-weight: 500;
  border-radius: 0.8rem;
  align-items: center;

  &:hover {
    background: ${colors.black10};
    color: ${colors.black85};
  }

  &:focus,
  &:focus-visible {
    background: transparent;
    color: ${colors.black85};
    outline: 2px solid ${colors.black25};
  }

  &:disabled {
    opacity: 50;
    cursor: not-allowed;
  }
`

export const triggerSizeSmall = css`
  font-size: 1.2rem;
  padding: 1rem;
`

export const triggerSizeMedium = css`
  font-size: 1.3rem;
  padding: 0.6rem;
`

export const content = css`
  background-color: ${colors.white};
  display: flex;

  flex-direction: column;
  margin-top: 0.8rem;
  border-radius: 1.4rem;
  border: 0.5px solid ${colors.black10};
  box-shadow: 0 5px 10px ${colors.black15}, 0 5px 10px ${colors.black10};
`

export const item = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0.6rem;
  color: ${colors.black85};

  margin: 0 0.8rem;

  font-size: 1.3rem;
  padding: 1.1rem 1.2rem 1rem;

  [data-dropdown-size='small'] & {
    font-size: 1.2rem;
    padding: 0.7rem 0.8rem 0.7rem;
  }

  :first-child {
    margin-top: 0.8rem;
  }

  :last-child {
    margin-bottom: 0.8rem;
  }

  &:hover {
    color: ${colors.green};
    background: ${colors.green10};
    outline: 0;
  }

  &:focus:not(&:hover),
  &:focus-visible:not(&:hover) {
    background: transparent;
    color: ${colors.black85};
    outline: 2px solid ${colors.black25};
  }

  &.active {
    padding-left: 1.8rem;
  }
`

export const section = css`
  color: ${colors.black60};

  &:hover {
    color: ${colors.black85};
    background: transparent;
  }
`
