import React from 'react'
import { errorCss } from './Input.styles'
import { capitalizeChild, cs } from '../../utils'
import { useInputContext } from './inputContext'

export interface InputErrorProps extends React.ComponentPropsWithoutRef<'div'> {
  /**
   * If true and if children is a string, capitalize the first letter of the string.
   */
  capitalize?: boolean
  /**
   * If true - it will add a default spacing (margin) styling
   */
  spacing?: boolean
}

export const InputError = (props: InputErrorProps) => {
  const { error, id } = useInputContext()
  const {
    children = error?.message,
    className,
    capitalize = true,
    spacing,
    ...rest
  }: typeof props = props

  return (
    <div
      id={`${id}-err`}
      className={cs(errorCss, className, spacing && cs.global('mt-8', 'ml-12'))}
      {...rest}
    >
      {capitalize ? capitalizeChild(children) : children}
    </div>
  )
}
