import React from 'react'
import { cs } from '../../../utils'
import { Primitive } from '../../Primitives'

type DivRef = Primitive.DivRef
type DivProps = Primitive.DivProps

type PaddedContentProps = DivProps &
  OneOf<
    { all: boolean },
    { top?: boolean; bottom?: boolean; left?: boolean; right?: boolean }
  >

/**
 * PaddedContent simply adds padding classes from the global styles (e.g. `p-32`)
 *
 * The benefit of using this component is that you know that you are getting the
 * default padding values meant for Modal content.
 */
export const PaddedContent = React.forwardRef<DivRef, PaddedContentProps>(
  (props, ref) => {
    const { className, all, top, bottom, left, right, ...rest } = props

    return (
      <Primitive.div
        ref={ref}
        className={cs(
          className,
          cs.global({
            'p-32': all,
            'pl-32': left,
            'pr-32': right,
            'pt-32': top,
            'pb-32': bottom,
          })
        )}
        {...rest}
      />
    )
  }
)

PaddedContent.displayName = 'PaddedContent'
