import React from 'react'
import moment from 'moment'

import {
  TransactionForm,
  TransactionFormValues,
  transactionFormSchema,
} from './TransactionForm'
import {
  Form,
  Modal,
  useForm,
  yupResolver,
  DropdownButton,
  Button,
} from '@quipu/components'
import {
  CreateExpenseDocument,
  CreateProjectExpenseDocument,
  GetProjectDetailsDocument,
  GetProjectTransactionsDocument,
  GetTransactionsDocument,
  TransactionType,
} from '@quipu/graphql/src/typemapImports'
import { useMutation } from '@quipu/apollo'

interface CreateTransactionModalProps {
  projectId?: string
  dismissModal: () => void
}

const initialValues: TransactionFormValues = {
  name: '',
  amount: '0',
  date: moment().format('YYYY-MM-DD HH:mm:ss'),
  draft: false,
  type: 'EXPENSE',
}

export const CreateTransactionModal = ({
  projectId,
  dismissModal,
}: CreateTransactionModalProps) => {
  // TODO: Replace this localStorage.getItem with a custom one
  const activeWorkspaceId = localStorage.getItem('activeWorkspace')!

  const showDraftButton = true

  const [createExpense, { loading: createExpenseLoading }] = useMutation(
    CreateExpenseDocument,
    {
      onCompleted: dismissModal,
      refetchQueries: [
        {
          query: GetTransactionsDocument,
          variables: {
            workspaceId: activeWorkspaceId,
            pagination: {
              limit: 20,
              page: 1,
            },
          },
        },
      ],
    }
  )

  const [createProjectExpense, { loading: createProjectLoading }] = useMutation(
    CreateProjectExpenseDocument,
    {
      onCompleted: dismissModal,
      refetchQueries: [
        {
          query: GetProjectDetailsDocument,
          variables: {
            id: projectId,
          },
        },
        {
          query: GetProjectTransactionsDocument,
          variables: {
            id: projectId,
            pagination: {
              limit: 20,
              page: 1,
            },
          },
        },
      ],
    }
  )

  const loading = createExpenseLoading || createProjectLoading

  const methods = useForm<TransactionFormValues>({
    mode: 'all',
    resolver: yupResolver(transactionFormSchema),
    defaultValues: initialValues,
  })

  const handleSubmit = async ({
    amount,
    files,
    date,
    ...values
  }: typeof initialValues) => {
    const filesMapped = files?.map(file => file.id)

    const commonFormData = {
      ...values,
      amount: parseFloat(amount || '0'),
      files: filesMapped,
      workspaceId: activeWorkspaceId,
      type: values.type as TransactionType,
      date: moment(date).format('YYYY-MM-DD HH:mm:ss'),
    }

    if (projectId) {
      createProjectExpense({
        variables: {
          input: {
            ...commonFormData,
            projectId,
          },
        },
      })
    } else {
      createExpense({
        variables: {
          input: {
            ...commonFormData,
            workspaceId: activeWorkspaceId,
          },
        },
      })
    }
  }

  return (
    <Modal.Card>
      <Form {...methods} onSubmit={handleSubmit}>
        <Modal.PaddedContent all>
          <Modal.Title>Create Transaction</Modal.Title>

          <TransactionForm {...methods} />
        </Modal.PaddedContent>

        <Modal.Footer>
          <Modal.Close />
          {showDraftButton ? (
            <DropdownButton
              type="submit"
              value={methods.getValues().draft ? 'draft' : 'default'}
              disabled={
                !methods.formState.isDirty || !methods.formState.isValid
              }
              onChange={({ value }) => {
                methods.setValue('draft', value === 'draft')
              }}
              options={[
                {
                  text: 'Save',
                  value: 'default',
                },
                {
                  text: 'Save as draft',
                  value: 'draft',
                },
              ]}
            />
          ) : (
            <Button type="submit" fullWidth disabled={loading}>
              Save
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal.Card>
  )
}
