import {
  DropdownMenuTriggerProps,
  Trigger,
} from '@radix-ui/react-dropdown-menu'

import { alterProps, cs } from '../../utils'
import * as styles from './Dropdown.styles'
import { Size } from './Dropdown.types'

export interface DropdownTriggerProps extends DropdownMenuTriggerProps {
  size?: Size
}

const SIZE_STYLES = {
  small: styles.triggerSizeSmall,
  medium: styles.triggerSizeMedium,
}

export const DropdownTrigger = alterProps(Trigger)<DropdownTriggerProps>(
  props => {
    const { size = 'medium', className, asChild, ...rest }: typeof props = props

    return {
      'data-dropdown-size': size,
      className: asChild
        ? className
        : cs(styles.trigger, SIZE_STYLES[size], className),
      ...rest,
    }
  }
)

DropdownTrigger.displayName = 'DropdownTrigger'
