import React, { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import * as s from './WorkspacesDropdown.styles'
import { getParsedItem, setItem } from '@quipu/utils'
import { useQuery } from '@quipu/apollo'
import { GetWorkspacesDocument, WorkspaceDetailsFragment } from '@quipu/graphql'
import { Routes } from '@quipu/modules-common'
import { Avatar, Dropdown, cs } from '@quipu/components'
import ChevronDownUpIcon from '@quipu/icons/assets/chevron-down-up-24.svg?react'

interface OptionProps {
  active?: boolean
  onClick: () => void
}

const OptionItem = ({
  children,
  active,
  onClick,
}: React.PropsWithChildren<OptionProps>) => (
  <Dropdown.Item className={cs(active && s.optionItemActive)} onClick={onClick}>
    {children}
  </Dropdown.Item>
)

const OptionSeparator = () => (
  <div
    key={`separator-${new Date().getTime()}`}
    className={s.optionSeparator}
  ></div>
)

export const WorkspacesDropdown: FC = () => {
  // Options
  const navigate = useNavigate()
  const locationParams = useParams<{ workspaceId: string }>()

  const activeWorkspace = getParsedItem('activeWorkspace')
  const { data } = useQuery(GetWorkspacesDocument)
  const allUserWorkspaces = useMemo(
    () => data?.workspaces.entries ?? [],
    [data]
  )

  const [selectedWorkspace, setSelectedWorkspace] =
    useState<WorkspaceDetailsFragment>()

  useEffect(() => {
    if (!selectedWorkspace && allUserWorkspaces.length > 0) {
      if (!activeWorkspace) {
        setSelectedWorkspace(allUserWorkspaces[0])
      } else {
        const workspace = allUserWorkspaces.find(
          ({ id }) => id === activeWorkspace
        )
        if (workspace) {
          setSelectedWorkspace(workspace)
        }
      }
    }
  }, [allUserWorkspaces, activeWorkspace, selectedWorkspace])

  const handleWorkspaceClick = (workspace: WorkspaceDetailsFragment) => {
    setItem('activeWorkspace', workspace.id)
    setSelectedWorkspace(workspace)

    navigate(Routes.routes.OVERVIEW.create({ workspaceId: workspace.id }))
  }

  // Map workspaces to workspaces dropdown
  const mappedWorkspaces = allUserWorkspaces.reduce((acc: any, workspace) => {
    return [
      ...acc,
      <OptionItem
        key={`${workspace.name}-item`}
        onClick={() => handleWorkspaceClick(workspace)}
        active={selectedWorkspace?.id === workspace!.id}
      >
        {workspace.name}
      </OptionItem>,
    ]
  }, [])

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild className={s.trigger}>
        <div className={s.toggleContainer}>
          {selectedWorkspace && <Avatar name={selectedWorkspace.name} />}
        </div>
        <ChevronDownUpIcon width="2.4rem" />
      </Dropdown.Trigger>
      <Dropdown.Content align="start" loop>
        <Dropdown.Section>Member of</Dropdown.Section>
        {...mappedWorkspaces}
        <OptionSeparator />
        <OptionItem
          key={`workspace-settings-item`}
          onClick={() =>
            navigate(
              Routes.routes.WORKSPACE_SETTINGS.create({
                workspaceId: locationParams.workspaceId!,
              })
            )
          }
        >
          Workspace Settings
        </OptionItem>
        <OptionItem
          key={`create-workspace-item`}
          onClick={() => navigate(Routes.routes.WORKSPACE_CREATE.create({}))}
        >
          Create Workspace
        </OptionItem>
      </Dropdown.Content>
    </Dropdown.Root>
  )
}
