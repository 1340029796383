import React from 'react'
import { useQuery } from '@quipu/apollo'
import { Button, Card, useModal } from '@quipu/components'
import { GetBudgetDocument } from '@quipu/graphql'
import { useParams } from 'react-router-dom'
import * as s from './ProjectBudgetView.styles'
import {
  Allocation,
  CreateBudgetAllocationForm,
  CreateBudgetModal,
} from './components'
import moment from 'moment'

export const ProjectBudgetView = () => {
  const locationParams = useParams<{ projectId: string }>()

  const { showModal } = useModal()

  const { data, loading } = useQuery(GetBudgetDocument, {
    variables: {
      projectId: locationParams.projectId!,
    },
  })

  const handleCreateBudgetClick = () => {
    const dismissModal = showModal({
      element: (
        <CreateBudgetModal
          projectId={locationParams.projectId!}
          dismissModal={() => dismissModal()}
        />
      ),
    })
  }

  const handleCreateBudgetAllocationClick = () => {
    showModal({
      element: <CreateBudgetAllocationForm budgetId={data?.budget.id!} />,
    })
  }

  if (loading) return <div>Loading...</div>

  if (!data?.budget)
    return (
      <div className={s.emptyStateContainer}>
        <Button onClick={handleCreateBudgetClick}>Add Budget</Button>
      </div>
    )

  const { totalAmount, spentAmount, allocations, endDate } = data.budget
  const numberOfDaysLeft = moment(endDate).diff(moment(), 'days')

  return (
    <>
      <div className={s.statisticsContainer}>
        <div className={s.statisticsLeftToSpent}>
          Left to spent for the next {numberOfDaysLeft} days
        </div>
        <div className={s.statisticsLeftToSpentAmount}>
          {totalAmount - spentAmount} €
        </div>
        <progress
          className={s.statisticsProgress}
          value={spentAmount}
          max={totalAmount}
        />

        <div className={s.statisticsTotalsContainer}>
          <div className={s.statisticsSpentAmountContainer}>
            <div className={s.statisticsSpentAmount}>{spentAmount} €</div>
            <span className={s.statisticsSpentAmountText}>Spent til now</span>
          </div>
          <div className={s.statisticsTotalAmount}>{totalAmount} €</div>
        </div>
      </div>

      <div className={s.allocationsContainer}>
        <Card
          className={s.addAllocationCard}
          onClick={handleCreateBudgetAllocationClick}
        >
          <span className={s.addAllocationText}>+ Allocation</span>
        </Card>

        {data?.budget.unallocatedAmount ? (
          <Allocation
            name="Others"
            color="#000000"
            spentAmount={data?.budget.unallocatedAmount}
          />
        ) : null}

        {allocations.map(allocation => (
          <Allocation
            key={`allocation-${allocation.id}`}
            name={allocation.category.name}
            color={allocation.category.color}
            allocatedAmount={allocation.allocatedAmount}
            spentAmount={allocation.spentAmount}
            remainingAmount={
              allocation.allocatedAmount - allocation.spentAmount
            }
          />
        ))}
      </div>
    </>
  )
}
