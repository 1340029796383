import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import { colors } from '@quipu/style-foundations'
import { Primitive } from '../Primitives'

export const SelectTrigger = css`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.9rem;
  padding: 1.1rem 1.2rem 1rem;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.5;
  width: 100%;
  gap: 0.5rem;
  background-color: ${colors.black5};
  color: ${colors.black50};
  cursor: pointer;

  &:hover {
    background: ${colors.black10};
    color: ${colors.black85};
  }

  &:focus,
  &:focus-visible {
    background: transparent;
    color: ${colors.black85};
    outline: 2px solid ${colors.black25};
  }

  &:disabled {
    opacity: 50;
    cursor: not-allowed;
  }
`

export const Container = styled(Primitive.div)`
  display: flex;
  width: 100%;
  gap: 0.8rem;
`

export const SelectItemText = css`
  display: flex;
  flex: 1;
`

export const SelectItemStyles = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0.6rem;
  color: ${colors.black85};

  margin: 0 0.8rem;

  font-size: 1.3rem;
  padding: 1.1rem 1.2rem 1rem;

  [data-dropdown-size='small'] & {
    font-size: 1.2rem;
    padding: 0.7rem 0.8rem 0.7rem;
  }

  :first-child {
    margin-top: 0.8rem;
  }

  :last-child {
    margin-bottom: 0.8rem;
  }

  &:hover {
    background: ${colors.black5};
    outline: 0;
  }

  &:focus:not(&:hover),
  &:focus-visible:not(&:hover) {
    background: transparent;
    color: ${colors.black85};
    outline: 2px solid ${colors.black25};
  }

  &.active {
    padding-left: 1.8rem;
    outline: none;
  }
`

export const IconContainer = styled(Primitive.div)`
  width: 3.2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${colors.black85};
`

export const IconIndicator = css`
  width: 1.6rem;

  [data-dropdown-size='small'] & {
    width: 1.2rem;
  }
`

export const SelectLabel = css`
  padding: 0 2.5rem;
  font-size: 1.2rem;
  line-height: 2.5rem;
  color: mauve;
`

export const SelectContent = css`
  background-color: ${colors.white};
  display: flex;
  width: var(--radix-popper-anchor-width);
  flex-direction: column;
  border-radius: 1.4rem;
  margin: 0.8rem 0 0.8rem 0;
  border: 0.5px solid ${colors.black10};
  box-shadow: 0 0.5rem 1rem ${colors.black15}, 0 0.5rem 1rem ${colors.black10};
  z-index: 300;
`

export const SelectScrollButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  background-color: white;
  color: violet;
  cursor: default;
`

export const SelectIcon = css`
  width: 1.6rem;
  display: flex;
  align-items: center;
`

export const errorCss = css`
  font-size: 1.4rem;
  line-height: 1.4rem;
  min-height: 1.4rem;
  color: hsl(3, 92%, 56%);
  display: block;
`
