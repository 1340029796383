import { css } from '@linaria/core'
import { colors } from '@quipu/style-foundations'

export const emptyStateContainer = css`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const statisticsContainer = css`
  display: flex;
  flex-direction: column;
`

export const statisticsLeftToSpent = css`
  font-size: 12px;
  line-height: 1.2;
`

export const statisticsLeftToSpentAmount = css`
  font-size: 26px;
  font-weight: bold;
  line-height: 1.5;
  color: hsl(0, 100%, 50%);
`

export const statisticsProgress = css`
  width: 100%;
  height: 2.2rem;
  border-radius: 11px;
  -webkit-appearance: none;
  appearance: none;

  &::-webkit-progress-bar {
    background: ${colors.black};
    border-radius: 11px;
  }

  &::-webkit-progress-value {
    background: ${colors.black25};
    border-radius: 11px;
  }
`

export const statisticsTotalsContainer = css`
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
`

export const statisticsSpentAmountContainer = css`
  display: flex;
  flex-flow: column;
`

export const statisticsSpentAmount = css`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: hsl(0, 0%, 17%);
`

export const statisticsSpentAmountText = css`
  font-size: 12px;
  line-height: 1.2;
  font-weight: 400;
  color: ${colors.black40};
`

export const statisticsTotalAmount = css`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: hsl(0, 100%, 50%);
`

/**
 * Allocations
 */
export const allocationsContainer = css`
  display: flex;
  flex-wrap: wrap;
  // To prevent shadows being cut
  padding-bottom: 1rem;
  margin-top: 4.8rem;
  gap: 4rem 2.4rem;
`

export const addAllocationText = css`
  font-size: 22px;
  font-weight: 700;
  color: ${colors.black55};
  width: 100%;
  text-align: center;
`

export const addAllocationCard = css`
  display: flex;
  min-width: 31.7rem;
  height: 6.2rem;
  align-items: center;
  cursor: pointer;
  padding: 1.4rem 1.6rem;
  margin-bottom: 2.4rem;

  :not(:first-child) {
    margin-left: 2.4rem;
  }

  :hover {
    background: hsl(180, 29%, 94%);
    cursor: pointer;
  }
`
