import { useEffect } from 'react'

/**
 * Updates body className according to dark mode state
 */
export const useToggleClassName = (isDarkMode: boolean) => {
  useEffect(() => {
    if (isDarkMode) {
      document.querySelector('body')?.classList.add('dark')
    } else {
      document.querySelector('body')?.classList.remove('dark')
    }
  }, [isDarkMode])
}
