import { styled } from '@linaria/react'
import {
  Root as ToggleGroupRoot,
  ToggleGroupMultipleProps,
  ToggleGroupSingleProps,
} from '@radix-ui/react-toggle-group'
import { colors } from '@quipu/style-foundations'

import { alterProps, useControllableState } from '../../utils'

const RootBase = styled(ToggleGroupRoot)`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  z-index: 1;
  background-color: ${colors.black5};

  border-radius: 0.9rem;
  padding: 0.4rem;

  &[data-segmented-control-size='small'] {
    border-radius: 0.7rem;
    padding: 0.2rem;
  }

  .dark & {
    background-color: ${colors.white10};
  }
`

export interface RootSingleProps
  extends OmitSafe<ToggleGroupSingleProps, 'type'> {}
export interface RootMultiProps extends ToggleGroupMultipleProps {}

export type Size = 'small' | 'medium'
export type SegmentsRootProps = RootSingleProps & {
  size?: Size
}

export const Root = alterProps(RootBase)<SegmentsRootProps>(props => {
  const {
    size,
    defaultValue,
    value: valueProp,
    onValueChange,
    ...rest
  }: typeof props = props

  /**
   * We are adding our own state here to disallow "unselecting" a segment.
   * See this example at Radix docs: https://www.radix-ui.com/primitives/docs/components/toggle-group#ensuring-there-is-always-a-value
   */
  const [value, setValue] = useControllableState({
    prop: valueProp,
    defaultProp: defaultValue,
    onChange: onValueChange,
  })

  const handleChange = (newValue: string) => newValue && setValue(newValue)

  return {
    'data-segmented-control-size': size,
    type: 'single',
    value,
    onValueChange: handleChange,
    ...rest,
  }
})
