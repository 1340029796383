import React, { useState, useRef, FC } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

// Styles
import * as s from './Sidebar.styles'
import { WorkspacesDropdown } from './WorkspacesDropdown'
import { useOnClickOutside } from '@quipu/utils'
import { Routes } from '@quipu/modules-common'
import { useBreakpoint } from '@quipu/style-foundations'
import { cs } from '@quipu/components'
import { Menu } from './Menu'
import { UserMenu } from './UserMenu'
import OverviewIcon from '@quipu/icons/assets/overview-32.svg?react'
import StatisticsIcon from '@quipu/icons/assets/statistics-32.svg?react'
import SplitBillIcon from '@quipu/icons/assets/split-transactions-32.svg?react'

interface SidebarProps {
  hideMenuItems?: boolean
}

export const Sidebar: FC<SidebarProps> = ({ hideMenuItems }) => {
  const navigate = useNavigate()
  const location = useLocation()
  // TODO: Replace this localStorage.getItem with a custom one
  const activeWorkspaceId = localStorage.getItem('activeWorkspace')!

  const [open, setOpen] = useState(false)

  const renderMenuItems = (url: string) => {
    return [
      {
        text: 'Overview',
        icon: <OverviewIcon />,
        url: Routes.routes.ROOT.create({}),
        active: Boolean(
          matchPath(Routes.routes.ROOT.template, url) ||
            matchPath(Routes.routes.OVERVIEW.template, url) ||
            matchPath(Routes.routes.PROJECT_DETAILS.template, url) ||
            matchPath(Routes.routes.PROJECT_BUDGET.template, url)
        ),

        onClick: () =>
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          handleRouteChange(
            Routes.routes.OVERVIEW.create({ workspaceId: activeWorkspaceId })
          ),
      },
      {
        text: 'Statistics',
        icon: <StatisticsIcon />,
        url: Routes.routes.STATISTICS.create({
          workspaceId: activeWorkspaceId,
        }),
        active: !!matchPath(Routes.routes.STATISTICS.template, url),
        onClick: () =>
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          handleRouteChange(
            Routes.routes.STATISTICS.create({ workspaceId: activeWorkspaceId })
          ),
      },
      {
        text: 'Split Transactions',
        icon: <SplitBillIcon />,
        url: Routes.routes.SPLIT_TRANSACTIONS.create({
          workspaceId: activeWorkspaceId,
        }),
        active: !!matchPath(Routes.routes.SPLIT_TRANSACTIONS.template, url),
        onClick: () =>
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          handleRouteChange(
            Routes.routes.SPLIT_TRANSACTIONS.create({
              workspaceId: activeWorkspaceId,
            })
          ),
      },
    ]
  }

  const [menuItems, setMenuItems] = useState(renderMenuItems(location.pathname))

  const handleRouteChange = (url: string) => {
    navigate(url)
    setMenuItems(renderMenuItems(url))
  }

  // Mobile
  const isMobile = !useBreakpoint('sm')
  const containerRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(containerRef, () => setOpen(false))

  return (
    <>
      {isMobile && (
        <div className={s.toggle} onClick={() => setOpen(true)}></div>
      )}

      <div
        ref={containerRef}
        className={cs(s.container, open && s.containerOpen)}
      >
        <WorkspacesDropdown />
        <div className={s.separator}></div>

        {!hideMenuItems && <Menu items={menuItems} />}

        <div className={s.userMenuContainer}>
          <UserMenu />
        </div>
      </div>
    </>
  )
}
