import React from 'react'
import ReactDOM from 'react-dom'
import { Button, cs, Grid, Loading, useModal } from '@quipu/components'
import { useBreakpoint } from '@quipu/style-foundations'
import { Layout } from '../../components/Layout'
import { useMutation, useQuery } from '@quipu/apollo'
import { DeleteExpenseDocument, GetTransactionsDocument } from '@quipu/graphql'
import { useTransactionsPagination } from '../../components/TransactionsList/useTransactionsPagination'
import {
  BaseFiltersType,
  Filters,
  FiltersState,
} from '../../components/Filters'
import { TransactionsList } from '../../components'
import {
  CreateTransactionModal,
  UpdateTransactionModal,
} from '../../components/TransactionForm'
import { formatDateToGQLResolver } from '@quipu/utils'

interface FiltersType extends BaseFiltersType {
  category?: string
}

const LayoutHeaderPortal = ({ children }: React.PropsWithChildren<{}>) => {
  const el = document.getElementById('layout-header-portal')
  if (!el) return null

  return ReactDOM.createPortal(children, el)
}

export const TransactionsView = () => {
  const { showModal } = useModal()

  const isMobile = !useBreakpoint('md')

  // TODO: Replace this localStorage.getItem with a custom one
  const activeWorkspaceId = localStorage.getItem('activeWorkspace')!

  const handleAddExpense = () => {
    const dismissModal = showModal({
      element: <CreateTransactionModal dismissModal={() => dismissModal()} />,
    })
  }

  const { data, fetchMore, networkStatus, refetch } = useQuery(
    GetTransactionsDocument,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        workspaceId: activeWorkspaceId,
        pagination: {
          limit: 20,
          page: 1,
        },
      },
    }
  )

  const expenses = data?.transactions.entries

  const [deleteExpense] = useMutation(DeleteExpenseDocument, {
    refetchQueries: [
      {
        query: GetTransactionsDocument,
        variables: {
          workspaceId: activeWorkspaceId,
          pagination: {
            limit: 20,
            page: 1,
          },
        },
      },
    ],
  })

  const handleDelete = (id: string) => {
    deleteExpense({
      variables: {
        id,
      },
    })
  }

  const { placeholders } = useTransactionsPagination({
    data: expenses || [],
    fetchMore,
    page: data?.transactions.pagination?.page! + 1,
    totalCount: data?.transactions.pagination?.total,
  })

  const handleFilterChange = <T extends keyof FiltersType>(
    name: T,
    value: NonNullable<FiltersType[T]>,
    { filters, setFilters, updateSearchValue }: FiltersState
  ) => {
    switch (name) {
      // case 'category': {
      //   setFilters({ ...filters, category: value as string })
      //   updateSearchValue(name as any, value)
      //   break
      // }

      default: {
        setFilters({ ...filters, [name]: value })
        updateSearchValue(name as any, value)
      }
    }
  }

  const handleSearch = async (filters: FiltersType) => {
    refetch({
      filters: {
        // parentExpense: id,
        name: filters.name,
        // categoryId: filters.category,
        date:
          filters.date?.[0] && filters.date?.[1]
            ? {
                startDate: formatDateToGQLResolver(filters.date[0]),
                endDate: formatDateToGQLResolver(filters.date[1]),
              }
            : undefined,
      },
    })
  }

  const render = () => {
    if (networkStatus === 1) {
      return <Loading />
    }

    if (!expenses?.length) {
      return (
        <>
          <LayoutHeaderPortal>
            <Filters onChange={handleFilterChange} onSubmit={handleSearch} />
          </LayoutHeaderPortal>

          <Grid container spacing={2}>
            You don&apos;t have expenses
          </Grid>
        </>
      )
    }

    return (
      <>
        <LayoutHeaderPortal>
          <Filters onChange={handleFilterChange} onSubmit={handleSearch} />
        </LayoutHeaderPortal>

        <TransactionsList
          items={expenses}
          canPinExpenses={false}
          onClick={item => {
            const dismissModal = showModal({
              element: (
                <UpdateTransactionModal
                  id={item?.id}
                  dismissModal={() => dismissModal()}
                />
              ),
            })
          }}
          onDeleteClick={handleDelete}
          placeholders={placeholders}
        />
      </>
    )
  }

  return (
    <Layout
      header={
        <>
          <div id="layout-header-portal"></div>

          {isMobile ? null : ( // <FloatingButton onClick={handleAddExpense} />
            <div className={cs.global('ml-24')}>
              <Button onClick={handleAddExpense}>Add transaction</Button>
            </div>
          )}
        </>
      }
    >
      {render()}
    </Layout>
  )
}
