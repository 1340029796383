import React, { MouseEventHandler, useState } from 'react'
import { Button, ButtonProps } from '../Button'
import { Dropdown } from '../Dropdown'
import * as styles from './DropdownButton.styles'

interface Option {
  text: string
  value: string
}

interface DropdownButtonProps {
  options: Option[]
  value?: string
  variant?: ButtonProps['variant']
  size?: ButtonProps['size']
  onClick?: MouseEventHandler<HTMLButtonElement>
  onChange?: (option: Option) => void
  type?: HTMLButtonElement['type']
  disabled?: boolean
}

export const DropdownButton = ({
  type,
  variant = 'primary',
  size = 'medium',
  value,
  disabled,
  options,
  onClick,
  onChange,
}: DropdownButtonProps) => {
  const initialOption = options.find(option => option.value === value)
  const [selectedOption, setSelectedOption] = useState<Option>(
    initialOption || options[0]
  )

  return (
    <div className={styles.container}>
      <Button
        type={type}
        onClick={onClick}
        variant={variant}
        size={size}
        className={styles.button}
        disabled={disabled}
      >
        {selectedOption?.text}
      </Button>
      <div className={styles.separator}></div>
      <Dropdown.Root>
        <Dropdown.Trigger asChild>
          <Button variant={variant} size={size} className={styles.trigger}>
            ...
          </Button>
        </Dropdown.Trigger>
        <Dropdown.Content style={{ zIndex: 1000 }}>
          {options.map(option => (
            <Dropdown.Item
              key={option.value}
              onClick={() => {
                setSelectedOption(option)
                onChange?.(option)
              }}
            >
              {option.text}
            </Dropdown.Item>
          ))}
        </Dropdown.Content>
      </Dropdown.Root>
    </div>
  )
}
