export const colors = {
  // Blacks
  black: 'hsla(0, 0%, 17%, 1)',
  black20: 'hsla(0, 0%, 17%, 0.20)',
  black40: 'hsla(0, 0%, 17%, 0.40)',
  black60: 'hsla(0, 0%, 17%, 0.60)',

  // Whites
  white: `hsla(0, 0%, 100%, 1)`,

  // Red
  red: 'hsla(0, 100%, 63%, 1)',
  red50: 'hsla(0, 100%, 63%, 0.50)',

  // Green
  green: 'hsla(180, 81%, 29%, 1)',
  green10: 'hsla(180, 81%, 29%, 0.10)',

  // Not used
  // Blacks
  black10: 'hsla(0, 0%, 0%, 0.1)',
  black15: 'hsla(0, 0%, 0%, 0.15)',
  black25: 'hsla(0, 0%, 0%, 0.25)',
  black3: 'hsla(0, 0%, 0%, 0.03)',
  black30: 'hsla(0, 0%, 0%, 0.3)',
  black35: 'hsla(0, 0%, 0%, 0.35)',
  black5: 'hsla(0, 0%, 0%, 0.05)',
  black50: 'hsla(0, 0%, 0%, 0.50)',
  black55: 'hsla(0, 0%, 0%, 0.55)',
  black75: 'hsla(0, 0%, 0%, 0.75)',
  black85: 'hsla(0, 0%, 0%, 0.85)',

  // Whites
  white5: `hsla(0, 0%, 100%, 0.05)`,
  white10: `hsla(0, 0%, 100%, 0.1)`,
  white25: `hsla(0, 0%, 100%, 0.25)`,
  white55: `hsla(0, 0%, 100%, 0.55)`,
  white85: `hsla(0, 0%, 100%, 0.85)`,
  white90: `hsla(0, 0%, 100%, 0.90)`,

  // Grays
  cultured55: 'hsla(0, 0%, 96%, 0.55)',
  cultured93: 'hsla(0, 0%, 98%, 0.93)',
  cultured7: 'hsla(0, 0%, 7%, 1)',
} as const
