import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@quipu/apollo'
import { Input, Select } from '@quipu/components'
import { CreateCategoryDocument, GetCategoriesDocument } from '@quipu/graphql'

interface Option {
  value: string
  label: string
  color: string
}

interface CategoryFieldProps {
  name: string
  label: string
  value?: string
  onCreate?: () => void
  onChange?: (options: Option | Option[]) => void
}

export const CategoryField = ({
  label,
  name,
  value: externalValue,
  onCreate,
  onChange,
}: CategoryFieldProps) => {
  // TODO: Replace this localStorage.getItem with a custom one
  const workspaceId = localStorage.getItem('activeWorkspace')!

  const [value, setValue] = useState<Option | undefined>()
  const [options, setOptions] = useState<Option[]>([])

  const { refetch } = useQuery(GetCategoriesDocument)

  const [createCategory] = useMutation(CreateCategoryDocument, {
    refetchQueries: [
      {
        query: GetCategoriesDocument,
        variables: {
          workspaceId,
        },
      },
    ],
    onCompleted: onCreate,
  })

  const loadOptions = async (searchValue: string) => {
    const result = await refetch({
      workspaceId,
      filters: {
        name: searchValue,
      },
    })

    const results = result.data.categories.entries.map(category => {
      return {
        value: category.id,
        label: category.name,
        color: category.color,
      }
    })

    setOptions(results)

    return results
  }

  const handleCreate = ({ value, color }: { value: string; color: string }) => {
    createCategory({
      variables: {
        input: {
          workspace: workspaceId,
          name: value,
          color,
        },
      },
    })
  }

  useEffect(() => {
    if (externalValue) {
      setValue(
        options.find(option => option.value === externalValue) || undefined
      )
    } else {
      setValue(undefined)
    }
  }, [externalValue, options])

  return (
    <Input.CustomFormField label={label} name={name}>
      <Select.SearchAsync
        value={value}
        loadOptions={loadOptions}
        onCreateNew={handleCreate}
        onChange={onChange}
      />
    </Input.CustomFormField>
  )
}
