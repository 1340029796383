import React, { FC } from 'react'

import * as s from './Tabs.styles'

interface Tab {
  name: string
  path: string
  active: boolean
}

interface TabsProps {
  tabs: Tab[]
  onClick: (path: string) => void
}

export const Tabs: FC<TabsProps> = ({ tabs, onClick }) => (
  <div className={s.container}>
    {tabs.map(tab => (
      <button
        key={`tab-${tab.name}`}
        className={s.button}
        data-active={tab.active}
        onClick={() => onClick(tab.path)}
      >
        {tab.name}
      </button>
    ))}
  </div>
)
