import { DropdownMenuItemProps, Item } from '@radix-ui/react-dropdown-menu'

import { alterProps, cs } from '../../utils'
import * as styles from './Dropdown.styles'

export interface DropdownItemProps extends DropdownMenuItemProps {}

export const itemCss = styles.item

export const DropdownItem = alterProps(Item)(props => {
  const { className, ...rest }: typeof props = props
  return { className: cs(styles.item, className), ...rest }
})
