import React from 'react'
import * as s from './OverviewView.styles'
import { useNavigate } from 'react-router-dom'
import { Card, useModal, Loading, cs } from '@quipu/components'
import { useQuery } from '@quipu/apollo'
import {
  GetProjectsDocument,
  GetTransactionsTotalsDocument,
  GetWorkspacesDocument,
} from '@quipu/graphql'
import { Routes } from '@quipu/modules-common'
import { Layout } from '../../components/Layout'
import { OverviewGridItem } from './components/OverviewGridItem/OverviewGridItem'
import { CreateProjectForm } from '../../components/ProjectForm'

export function OverviewView() {
  const navigate = useNavigate()

  const { showModal } = useModal()

  // TODO: Replace this localStorage.getItem with a custom one
  const activeWorkspaceId = localStorage.getItem('activeWorkspace')!
  const { data } = useQuery(GetWorkspacesDocument)
  const currentWorkspace = data?.workspaces.entries.find(
    ({ id }) => activeWorkspaceId === id
  )

  // Expenses
  const expensesResult = useQuery(GetTransactionsTotalsDocument, {
    variables: { workspaceId: activeWorkspaceId },
  })

  // Projects
  const projectsResult = useQuery(GetProjectsDocument, {
    variables: {
      workspaceId: activeWorkspaceId,
    },
    notifyOnNetworkStatusChange: true,
  })

  // TODO: Implement upcoming transactions
  // const upcomingResult = useQuery(GetUpcomingTransactionsDocument, {
  //   variables: { workspaceId: activeWorkspaceId },
  // })

  const handleAddProject = () => {
    const dismissModal = showModal({
      element: <CreateProjectForm dismissModal={() => dismissModal()} />,
    })
  }

  return (
    <Layout
      header={
        <>
          {currentWorkspace ? (
            <h1 className={cs(s.workspaceTitle, s.container)}>
              {currentWorkspace?.name} workspace
            </h1>
          ) : null}
        </>
      }
      contentContainerStyleOverrides={s.layoutContainer}
    >
      <div className={s.container}>
        {expensesResult.loading || projectsResult.loading ? <Loading /> : null}

        <s.FutureItem marginBottom>
          <h1>Upcoming transactions</h1>
          <p>
            Here we will show all transactions that will be executed within a
            week.
          </p>
        </s.FutureItem>

        <div className={s.overviewGridContainer}>
          <OverviewGridItem
            id="general-expenses"
            name="General Expenses"
            income={expensesResult.data?.transactions.totals.income || 0}
            expense={expensesResult.data?.transactions.totals.expense || 0}
            onClick={() =>
              navigate(
                Routes.routes.TRANSACTIONS.create({
                  workspaceId: activeWorkspaceId,
                })
              )
            }
          />

          {projectsResult.data?.projects.entries.map(project => (
            <OverviewGridItem
              key={`overview-project-${project.id}`}
              id={project.id}
              name={project.name}
              date={project.date}
              income={project.transactions.totals.income}
              expense={project.transactions.totals.expense}
              onClick={projectId => {
                navigate(
                  Routes.routes.PROJECT_DETAILS.create({
                    workspaceId: activeWorkspaceId,
                    projectId,
                  })
                )
              }}
            />
          ))}

          <Card
            className={s.addProjectCard}
            variant="secondary"
            onClick={handleAddProject}
            borderStyle="dotted"
          >
            <span className={s.addProjectText}>+ Project</span>
          </Card>
        </div>

        <s.FutureItem marginTop>
          <h1>Chart</h1>
          <p>Yearly chart showing income/ expenses from all projects.</p>
        </s.FutureItem>
      </div>
    </Layout>
  )
}
