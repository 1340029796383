import React, { FC, useState } from 'react'
import CreatableSelect from 'react-select/async-creatable'
import { colors } from '@quipu/style-foundations'

import { COLORS } from './colors'

// Styles
const singleValueStyles = (provided: any, state: any, override: any) => {
  const opacity = state.isDisabled ? 0.5 : 1
  const transition = 'opacity 300ms'

  return {
    ...provided,
    opacity,
    transition,
    fontSize: 12,
    fontWeight: 700,
    color: colors.white,
    background: state.data.color,
    borderRadius: 4,
    padding: '2px 8px',
    textTransform: 'capitalize',
    ...override,
  }
}

const baseCustomStyles = {
  menu: (provided: any) => ({
    ...provided,
    fontSize: 12,
    textTransform: 'capitalize',
  }),

  menuList: (provided: any) => ({
    ...provided,
    maxHeight: '150px',
    overflowY: 'auto',
  }),

  control: (provided: any) => ({
    ...provided,
    fontSize: 12,
    fontWeight: 300,
  }),

  singleValue: (provided: any, state: any) => {
    return singleValueStyles(provided, state, {})
  },

  multiValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return {
      ...provided,

      opacity,
      transition,
      fontSize: 12,
      fontWeight: 700,
      color: colors.white,
      background: state.data.color,
      borderRadius: 4,
      padding: '2px 8px',
    }
  },

  multiValueLabel: (styles: any, { data }: any) => {
    return {
      ...styles,
      color: colors.white,
      backgroundColor: data.color,
    }
  },
}

// Types
interface Option {
  value: string
  label: string
  color: string
}

interface SearchProps {
  multi?: boolean
  placeholder?: string
  onCreateNew?: (option: Option) => void
  onChange?: (option: Option | Option[]) => void
  value?: Option | Option[]
  loadOptions?: (value: string) => void
}

export const SearchAsync: FC<SearchProps> = ({
  onCreateNew,
  placeholder = '',
  multi = false,
  value,
  loadOptions,
  onChange,
}) => {
  const [customStyles, setCustomStyles] = useState(baseCustomStyles)

  return (
    <CreatableSelect
      placeholder={placeholder}
      cacheOptions
      isClearable
      menuPosition="absolute"
      defaultOptions
      isMulti={multi}
      loadOptions={loadOptions}
      styles={customStyles}
      value={value}
      onChange={(option: any) => {
        const { ...newCustomStyles } = customStyles

        if (option?.__isNew__!) {
          const randomColorIndex = Math.floor(
            Math.random() * Object.keys(COLORS).length - 1
          )
          const color = Object.values(COLORS)[randomColorIndex]
          newCustomStyles.singleValue = (provided: any, state: any) =>
            singleValueStyles(provided, state, {
              background: color,
            })

          const newValue: Option = {
            label: option.label,
            color,
            value: crypto.randomUUID(),
          }
          onCreateNew?.(newValue)
        } else {
          if (option) {
            newCustomStyles.singleValue = (provided: any, state: any) =>
              singleValueStyles(provided, state, {
                background: option.color,
              })
          }
          onChange?.(option)
        }

        setCustomStyles(newCustomStyles)
      }}
    />
  )
}
