import {
  Content,
  DropdownMenuContentProps,
  Portal,
} from '@radix-ui/react-dropdown-menu'
import React from 'react'

import { cs } from '../../utils'
import { Primitive } from '../Primitives'
import * as styles from './Dropdown.styles'
import { Size } from './Dropdown.types'

export interface DropdownContentProps extends DropdownMenuContentProps {
  size?: Size
}

export const DropdownContent = React.forwardRef<
  Primitive.DivRef,
  DropdownContentProps
>((props, ref) => {
  const { className, size = 'medium', ...rest }: typeof props = props
  return (
    <Portal>
      <Content
        ref={ref}
        className={cs(styles.content, className)}
        data-dropdown-size={size}
        {...rest}
      />
    </Portal>
  )
})
DropdownContent.displayName = 'DropdownContent'
