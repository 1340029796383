import { css } from '@linaria/core'
// import TagUnstyled from 'components/Tag/Tag'

export const parentExpenseHeaderContainer = css`
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
`

// export const Tag = styled(TagUnstyled)`
//   margin-left: 10px;
// `
