import React, { createContext, useMemo, useState } from 'react'

import { DarkModeProps } from './DarkMode.types'
import { useSystemDarkMode } from './useSystemDarkMode'
import { useToggleClassName } from './useToggleClassName'

/**
 * DarkModeContext
 *
 * Returns "toggleDarkMode" which allows us to switch
 * dark mode on and off
 */
export const DarkModeContext = createContext({
  toggleDarkMode: () => {},
  isDarkMode: false,
})

export const DarkModeProvider: React.FC<DarkModeProps> = ({ children }) => {
  const isSystemUsingDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches
  const userSavedThemePreference = localStorage.getItem('theme')
  const isUserUsingDarkMode = userSavedThemePreference === 'dark'
  const [isDarkMode, setIsDarkMode] = useState(
    userSavedThemePreference ? isUserUsingDarkMode : isSystemUsingDarkMode
  )

  const handleDarkMode = (e: MediaQueryListEvent) => {
    const theme = e.matches ? 'dark' : 'light'
    localStorage.setItem('theme', theme)
    setIsDarkMode(e.matches)
  }

  useToggleClassName(isDarkMode)
  useSystemDarkMode(handleDarkMode)

  const toggleDarkMode = useMemo(() => {
    const handleToggleDarkMode = () => {
      setIsDarkMode(prev => {
        const theme = prev ? 'light' : 'dark'

        localStorage.setItem('theme', theme)
        return !prev
      })
    }

    return {
      isDarkMode,
      toggleDarkMode: handleToggleDarkMode,
    }
  }, [isDarkMode])

  return (
    <DarkModeContext.Provider value={toggleDarkMode}>
      {children}
    </DarkModeContext.Provider>
  )
}
