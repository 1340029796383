import { css } from '@linaria/core'
import { breakpoint, colors } from '@quipu/style-foundations'

export const container = css`
  display: flex;
  flex-direction: column;
  width: 10rem;
  border-right: 1px solid ${colors.black20};
  padding: 3.2rem 0 1.6rem 0;
  background: ${colors.white};

  ${breakpoint('base', 'sm')} {
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    padding: 12px 0;
    transition: all 0.5s linear;
    border-right: 0;
    box-shadow: 10px 0px 8px -2px ${colors.black};
  }
`

export const separator = css`
  height: 1px;
  width: 100%;
  background: ${colors.black20};
  margin-top: 32px;
`

export const menu = css`
  margin-top: 32px;

  ${breakpoint('base', 'sm')}{
    margin-top: 32px;}
  }
`

export const containerOpen = css`
  top: 0;
  left: 0;
`

export const userMenuContainer = css`
  margin-top: auto;
`

export const toggle = css`
  position: absolute;
  top: 12px;
  left: 16px;
`
