import React from 'react'

import { InputRoot } from '../InputRoot'
import { InputLabel } from '../InputLabel'
import { InputError } from '../InputError'
import { FieldValues, UseFormReturn, useFormContext } from 'react-hook-form'
import { cs } from '../../../utils'

interface CustomChildren {
  (methods: UseFormReturn<FieldValues>): React.ReactNode
}

interface CustomFormFieldProps {
  name: string
  label: string
  showErrors?: boolean
  required?: boolean
  children?: React.ReactNode | CustomChildren
}

export const CustomFormField = (props: CustomFormFieldProps) => {
  const { name, label, showErrors = false, children } = props
  const methods = useFormContext()
  const { register, formState } = methods

  return (
    <InputRoot name={name} formState={formState} register={register}>
      <InputLabel>{label}</InputLabel>

      <div className={cs.global('mt-4')}>
        {typeof children === 'function' ? children(methods) : children}
      </div>

      {showErrors ? <InputError /> : null}
    </InputRoot>
  )
}
