import { styled } from '@linaria/react'
import { css } from '@linaria/core'

import { colors } from '@quipu/style-foundations/src'
import { Primitive } from '../Primitives'

export const ButtonBase = styled(Primitive.button)`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  height: fit-content;
  width: fit-content;

  font-weight: 600;
  line-height: 1.6rem;

  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`

// Variants
export const variantPrimary = css`
  color: ${colors.white};
  background-color: ${colors.black85};
  box-shadow: 0 0.1rem 0.3rem 0 ${colors.black10};

  &:hover {
    color: ${colors.white};
    background-color: ${colors.black50};
    box-shadow: 0 0.1rem 0.3rem 0 ${colors.black25};
  }

  &:focus,
  &:focus-visible {
    color: ${colors.white};
    background-color: ${colors.black85};
    box-shadow: 0 0.1rem 0.3rem 0 ${colors.black10};
    outline: 0.2rem solid ${colors.black25};
  }

  &:active {
    color: ${colors.black55};
    background-color: ${colors.black50};
    box-shadow: 0 0.1rem 0.2rem 0 ${colors.black10};
  }

  .dark & {
    color: ${colors.white85};
    background-color: ${colors.green};
    box-shadow: 0 0.1rem 0.3rem 0 ${colors.black10};

    &:hover {
      color: ${colors.black85};
      background-color: ${colors.black55};
      box-shadow: 0 0.1rem 0.3rem 0 ${colors.black25};
    }

    &:focus,
    &:focus-visible {
      color: ${colors.black85};
      background-color: ${colors.white85};
      box-shadow: 0 0.1rem 0.3rem 0 ${colors.black10};
      outline: 0.2rem solid ${colors.white25};
    }

    &:active {
      color: ${colors.black5};
      background-color: ${colors.white55};
      box-shadow: 0 0.1rem 0.2rem 0 ${colors.black10};
    }
  }
`

export const variantSecondary = css`
  color: ${colors.black85};
  background: ${colors.white};
  border: 0.05rem solid ${colors.black10};

  &:hover {
    color: ${colors.black85};
    background-color: ${colors.black5};
    border: 0.05rem solid ${colors.black10};
    box-shadow: 0 0.1rem 0.3rem 0 ${colors.black25};
  }

  &:focus,
  &:focus-visible {
    color: ${colors.black85};
    background-color: ${colors.white85};
    outline: 0.2rem solid ${colors.black25};
    border: 0.05rem solid ${colors.black10};
    box-shadow: 0 0.1rem 0.3rem 0 ${colors.black10};
  }

  &:active {
    color: ${colors.black50};
    background-color: ${colors.black5};
    border: 0.05rem solid ${colors.black10};
    box-shadow: 0 0.1rem 0.2rem 0 ${colors.black10};
  }

  .dark & {
    color: ${colors.white85};
    background: ${colors.black5};
    border: 0.05rem solid ${colors.black10};

    &:hover {
      color: ${colors.white85};
      background-color: ${colors.black25};
      border: none;
      box-shadow: 0 0.1rem 0.3rem 0 ${colors.black25};
    }

    &:focus,
    &:focus-visible {
      color: ${colors.white85};
      background-color: ${colors.white5};
      outline: 0.2rem solid ${colors.white25};
      border: none;
      box-shadow: 0 0.1rem 0.3rem 0 ${colors.black10};
    }

    &:active {
      color: ${colors.white55};
      background-color: ${colors.white25};
      border: none;
      box-shadow: 0 0.1rem 0.2rem 0 ${colors.black10};
    }
  }
`

export const variantTertiary = css`
  color: ${colors.black85};
  background: transparent;

  &:hover {
    color: ${colors.black85};
    background-color: ${colors.black5};
  }

  &:focus,
  &:focus-visible {
    color: ${colors.black85};
    outline: 0.2rem solid ${colors.black25};
  }

  &:active {
    color: ${colors.black50};
    background-color: ${colors.black5};
  }

  .dark & {
    color: ${colors.white85};
    background: transparent;

    &:hover {
      color: ${colors.white85};
      background-color: ${colors.white25};
    }

    &:focus,
    &:focus-visible {
      color: ${colors.white85};
      outline: 0.2rem solid ${colors.white25};
    }

    &:active {
      color: ${colors.white55};
      background-color: ${colors.white25};
    }
  }
`

export const variantDestructive = css`
  color: ${colors.red};
  background-color: ${colors.white85};
  border: 0.05rem solid ${colors.black10};
  box-shadow: 0 0.1rem 0.2rem 0 ${colors.black10};

  &:hover {
    background-color: ${colors.black5};
    border: 0.05rem solid ${colors.black10};
    box-shadow: 0 0.1rem 0.3rem 0 ${colors.black25};
  }

  &:focus,
  &:focus-visible {
    background-color: ${colors.white85};
    outline: 0.2rem solid ${colors.black25};
    border: 0.05rem solid ${colors.black10};
    box-shadow: 0 0.1rem 0.3rem 0 ${colors.black10};
  }

  &:active {
    color: ${colors.red50};
    background-color: ${colors.black5};
    border: 0.05rem solid ${colors.black10};
    box-shadow: 0 0.1rem 0.2rem 0 ${colors.black10};
  }

  .dark & {
    color: ${colors.red};
    background-color: ${colors.white5};
    border: none;
    box-shadow: 0 0.1rem 0.2rem 0 ${colors.black10};

    &:hover {
      background-color: ${colors.white25};
      border: none;
      box-shadow: 0 0.1rem 0.3rem 0 ${colors.black25};
    }

    &:focus,
    &:focus-visible {
      background-color: ${colors.white5};
      outline: 0.2rem solid ${colors.white25};
      border: none;
      box-shadow: 0 0.1rem 0.3rem 0 ${colors.black10};
    }

    &:active {
      color: ${colors.red50};
      background-color: ${colors.white25};
      border: none;
      box-shadow: 0 0.1rem 0.2rem 0 ${colors.black10};
    }
  }
`

// Sizes
export const sizeSmall = css`
  font-size: 1.2rem;
  border-radius: 0.7rem;
  padding: 0.6rem 1.6rem;
`

export const sizeMedium = css`
  font-size: 1.3rem;
  border-radius: 0.9rem;
  padding: 1rem 2.4rem;
`

export const fullWidth = css`
  width: 100%;
  justify-content: center;
`
