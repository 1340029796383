import { css } from '@linaria/core'

export const container = css`
  display: flex;
`

export const filterContainer = css`
  display: flex;
  flex-direction: column;
  width: 17.8rem;
  padding-right: 3.2rem;
  margin-right: 3.2rem;
  border-right: 1px solid hsl(0, 0%, 90%);
  color: red;
`

export const dropdownFilterContent = css`
  position: relative;
  color: hsl(0, 0%, 0%);
`

export const dropdownToggle = css`
  background: none;
  padding: 0;

  :hover {
    background: none;
  }

  :focus,
  :focus-visible {
    outline: 0;
  }
`

export const filterTitle = css`
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 500;
  color: hsl(0, 0%, 17%);
`

export const totalContainer = css`
  display: flex;
  flex-direction: column;

  :last-child {
    margin-left: 4rem;
  }
`

export const totalTitle = css`
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 400;
  color: hsl(0, 0%, 52%);
`

export const totalValue = `
  font-size: 3.2rem;
  line-height: 1.1;
  font-weight: 600;
  color: hsl(0, 0%, 17%);
`

export const totalValueSpent = css`
  ${totalValue};
  color: hsl(0, 100%, 63%);
`

export const totalValueIncome = css`
  ${totalValue};
  color: hsl(180, 81%, 29%);
`
