import { css } from '@linaria/core'
import { colors } from '@quipu/style-foundations'

export const container = css`
  display: flex;
  border-radius: 8px;
  padding: 0.4rem 0.8rem;
  height: 4.8rem;
  // TODO: Fix this color, it should be HSLA
  background: rgba(230, 230, 230, 0.3);
`

export const button = css`
  height: 4rem;
  font-size: 16px;
  font-weight: 500;

  border: 1px solid transparent;
  outline: 0;
  cursor: pointer;
  border-radius: 8px;
  padding: 0 24px;
  background: transparent;
  color: ${colors.black60};

  :not(:last-child) {
    margin-right: 0.6rem;
  }

  :hover {
    font-weight: 600;
    color: ${colors.green};
    background: ${colors.white};
    box-shadow: 0 5px 5px -3px hsla(0, 0%, 0%, 0.16),
      0 8px 10px 1px hsla(0, 0%, 0%, 0.05), 0 3px 14px 2px hsla(0, 0%, 0%, 0.05);
  }

  &[data-active='true'] {
    font-weight: 600;
    color: ${colors.green};
    background: ${colors.white};
    box-shadow: 0 5px 5px -3px hsla(0, 0%, 0%, 0.16),
      0 8px 10px 1px hsla(0, 0%, 0%, 0.05), 0 3px 14px 2px hsla(0, 0%, 0%, 0.05);
  }
`
