import { css } from '@linaria/core'

// max-height: ${({ maxHeight }) => maxHeight || 'initial'};
// color: ${({ theme }) => theme.colors.mineShaft};
export const container = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 16px 8px;
`

// cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
export const row = css`
  width: 100%;
  display: flex;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.08);

  :not(:last-child) {
    margin-bottom: 16px;
  }

  :hover {
  }
`
// background: ${({ theme }) => `${theme.colors.surfieGreen}10`};

// ${layout}
export const column = css`
  display: flex;
`

export const actionsMenuColumn = css`
  justify-content: center;
`
