import React from 'react'
import { Button, DatePicker } from '@quipu/components'
import { SearchMenuProps } from './Filters.types'

export const CommonFilters = ({
  filters,
  setFilters,
  updateSearchValue,
  filterComponents,
  onFilterChange,
  onDatePickerOpenChange,
}: SearchMenuProps) => {
  const arr =
    filterComponents?.({ filters, setFilters, updateSearchValue })?.map(
      filter => {
        switch (filter.type) {
          case 'button': {
            return (
              <Button
                key={`filter-${filter.name}`}
                size="small"
                variant="tertiary"
                // icon={filter.icon}
                // active={!!filter.value}
                onClick={() =>
                  onFilterChange(filter.name as any, !filter.value)
                }
              >
                {filter.displayName}
              </Button>
            )
          }

          case 'choice': {
            break
            // return (
            //   <Dropdown
            //     key={`filter-${filter.name}`}
            //     toggle={<>{filter.displayName}</>}
            //     toggleProps={{
            //       icon: <CategoryOutlined />,
            //       elementVariant: 'button',
            //       active: !!filter.value,
            //     }}
            //     options={filter.options({
            //       filters,
            //       setFilters,
            //       updateSearchValue,
            //     })}
            //   />
            // )
            return null
          }
        }
      }
    ) || []

  return [
    ...arr,
    <DatePicker
      key={`filter-date-range`}
      placeholderText="Date"
      selectsRange
      startDate={filters?.date?.[0]}
      endDate={filters?.date?.[1]}
      onCalendarOpen={() => onDatePickerOpenChange(true)}
      onCalendarClose={() => onDatePickerOpenChange(false)}
      onChange={(value: [Date | null, Date | null]) =>
        onFilterChange('date', value)
      }
      // TODO: Review these extra props
      excludeScrollbar={true}
      onSelect={() => {}}
    />,
  ]
}
