import React from 'react'
import { cs } from '../../utils'

import {
  ButtonBase,
  variantPrimary,
  variantSecondary,
  variantTertiary,
  variantDestructive,
  sizeSmall,
  sizeMedium,
  fullWidth as fullWidthStyles,
} from './Button.styles'
import { Primitive } from '../Primitives'

type Variant = 'primary' | 'secondary' | 'tertiary' | 'destructive'

type Size = 'small' | 'medium'

type ButtonPrimProps = Primitive.ButtonProps

const VARIANT_STYLES = {
  primary: variantPrimary,
  secondary: variantSecondary,
  tertiary: variantTertiary,
  destructive: variantDestructive,
}

const SIZE_STYLES = {
  small: sizeSmall,
  medium: sizeMedium,
}

export interface ButtonProps extends ButtonPrimProps {
  variant?: Variant
  size?: Size
  fullWidth?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      className,
      variant = 'primary',
      size = 'medium',
      type = 'button',
      fullWidth,
      ...otherProps
    },
    ref
  ) {
    return (
      <ButtonBase
        ref={ref}
        type={type}
        className={cs(
          VARIANT_STYLES[variant],
          SIZE_STYLES[size],
          fullWidth ? fullWidthStyles : undefined,
          className
        )}
        {...otherProps}
      />
    )
  }
)
