import { css } from '@linaria/core'
import { colors } from '@quipu/style-foundations'

export const sizeSmall = css`
  font-size: 1.2rem;
`

export const sizeMedium = css`
  font-size: 1.3rem;
`
export const labelCss = css`
  color: ${colors.black55};
  font-weight: 500;
`
