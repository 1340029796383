import { createRoutes } from './TypedRoute'

export const Routes = createRoutes({
  ROOT: '/',
  OVERVIEW: '/:workspaceId',

  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  SIGN_OUT: '/sign-out',
  ACTIVATE_ACCOUNT: '/activate-account/:token',
  SETTING: '/settings',

  TRANSACTIONS: '/:workspaceId/t',

  PROJECT_DETAILS: '/:workspaceId/p/:projectId',
  PROJECT_BUDGET: '/:workspaceId/p/:projectId/budget',

  WORKSPACE_CREATE: '/workspace/create',
  WORKSPACE_SETTINGS: '/:workspaceId/settings',
  WORKSPACE_SETTINGS_CATEGORIES: '/:workspaceId/settings/categories',
  WORKSPACE_SETTINGS_TAGS: '/:workspaceId/settings/tags',
  WORKSPACE_SETTINGS_MEMBERS: '/:workspaceId/settings/members',
  WORKSPACE_ACCEPT_INVITATION: '/:workspaceId/accept-invitation/:token',

  STATISTICS: '/:workspaceId/statistics',

  SPLIT_TRANSACTIONS: '/:workspaceId/split-transactions',

  CATCH_ALL: '/*',
} as const)
