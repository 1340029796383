import { css } from '@linaria/core'

export const filterContainer = css`
  display: flex;
  margin-bottom: 3.8rem;
`
export const expenseListContainer = css`
  display: flex;

  overflow: auto;
`
