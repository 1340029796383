import { css } from '@linaria/core'

export const container = css`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    margin-bottom: 2rem;
  }

  p,
  a {
    margin-top: 1.2rem;
  }
`
