import React from 'react'

import { Primitive } from '../Primitives'
import * as styles from './TextArea.styles'
import { useInputContext } from './inputContext'
import { cs, setRefs } from '../../utils'

export interface InputRef extends Primitive.TextareaRef {}
export interface InputProps extends Primitive.TextareaProps {}

export const TextareaBase = React.forwardRef<InputRef, InputProps>(
  (props, ref) => {
    const { ...rest }: typeof props = props
    const { id, forward } = useInputContext()

    const { ref: contextRef, ...forwardRest } = forward || {}

    const passDown = { ...(forwardRest || {}), ...rest }

    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <Primitive.textarea
        ref={r => setRefs(r, ref, contextRef)}
        id={id || undefined}
        {...passDown}
        className={cs(styles.textArea, rest.className)}
      />
    )
  }
)
TextareaBase.displayName = 'TextareaBase'
