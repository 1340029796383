import { styled } from '@linaria/react'
import { css } from '@linaria/core'

import { colors } from '@quipu/style-foundations/src'

export const LinkBase = styled.a`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  height: fit-content;
  width: fit-content;

  font-weight: 600;

  color: ${colors.green};

  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`

// Sizes
export const sizeSmall = css`
  font-size: 1.2rem;
`

export const sizeMedium = css`
  font-size: 1.3rem;
`

export const fullWidth = css`
  width: 100%;
  justify-content: center;
`
