import { useContext } from 'react'
import { uniqueId } from '@quipu/utils'

import { MultipleToasts } from './ToastProvider'
import { ToastProps, ToastVariants } from './'

/**
 * Prepare props for both examples:
 *
 * - showToast('some message', 'positive')
 * - showToast({...}: ToastProps)
 *
 * also injects an `id` for toast management in MultipleToasts provider
 */
const prepareProps = (props: UseToastProps, variant?: ToastVariants) => {
  const id = uniqueId()

  if (typeof props === 'string') {
    return { id, description: props, variant: variant }
  }

  return { id, ...props }
}

type UseToastProps =
  | Pick<
      ToastProps,
      'title' | 'description' | 'action' | 'variant' | 'withClose' | 'type'
    >
  | string

/**
 * useToast
 *
 * This hooks allows us to trigger a Toast
 *
 * @example
 * const { showToast } = useToast()
 * - showToast('Hello, I am a Toast')
 * - showToast('Hello, I am a Toast', 'positive')
 *
 * OR
 *
 * showToast({
 *  title: "Title"
 *  description: "Description"
 *  variant: "positive"
 *  action: {
 *    altText: 'Some Action',
 *    component: <button>Action</button>
 *  }
 *  withClose: false
 * })
 *
 *
 */
export const useToast = () => {
  const { addToast, removeToast } = useContext(MultipleToasts)

  const showToast = (
    toastProps: UseToastProps,
    variant?: ToastVariants
  ): ToastProps => {
    const props = prepareProps(toastProps, variant)

    addToast(props)

    return props
  }

  return {
    showToast,
    removeToast,
  }
}
