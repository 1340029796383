import React from 'react'

import { Container, Border, Image, NoImage } from './Avatar.styles'

export interface AvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string
  src?: string
  className?: string
}

export const Avatar: React.FC<AvatarProps> = React.forwardRef<
  HTMLDivElement,
  AvatarProps
>((props, ref) => {
  const { name, src, className, ...rest } = props

  let content
  if (src) {
    content = <Image src={src} alt={name} />
  } else {
    content = (
      <Border>
        <NoImage>{name.charAt(0)}</NoImage>
      </Border>
    )
  }

  return (
    <Container ref={ref} className={className} {...rest}>
      {content}
    </Container>
  )
})

Avatar.displayName = 'Avatar'
