import React, { FC } from 'react'

import * as s from './Menu.styles'
import { cs } from '@quipu/components'

// Types
interface MenuItems {
  text: string
  icon: any
  url: string
  active: boolean
  onClick: () => void
}

interface MenuProps {
  items: MenuItems[]
  className?: string
}

export const Menu: FC<MenuProps> = ({ items, className }) => {
  return (
    <nav className={cs(s.container, className)}>
      {items.map(item => (
        <a
          className={cs(s.menuButton, item.active && s.menuButtonActive)}
          key={`menu-item-${item.text}`}
          href={item.url}
          onClick={event => {
            event.preventDefault()
            item.onClick()
          }}
        >
          {item.icon}
          <div className={s.menuButtonText}>{item.text}</div>
        </a>
      ))}
    </nav>
  )
}
