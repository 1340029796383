import { css } from '@linaria/core'
import { colors } from '@quipu/style-foundations/src'

export const HIDE_ANIMATION_DURATION = 350

const overlayShow = `
  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: overlayShow 300ms cubic-bezier(0.16, 1, 0.3, 1);
`

const contentShow = `
  @keyframes contentShow {
    from {
      opacity: 0;
      transform: translate(0, 5%) scale(0.5);
    }
    to {
      opacity: 1;
      transform: translate(0, 0%) scale(1);
    }
  }

  animation: contentShow 300ms cubic-bezier(0.16, 1, 0.3, 1);
`

const hide = `
  @keyframes hide {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  animation: hide ${HIDE_ANIMATION_DURATION}ms cubic-bezier(0.16, 1, 0.3, 1);
`

export const overlay = css`
  z-index: 200;
  cursor: pointer;

  background-color: ${colors.black15};
  position: fixed;
  inset: 0;

  ${overlayShow}

  &[data-state='closed'] {
    ${hide};
  }
`

export const content = css`
  z-index: 201;
  position: fixed;
  inset: 0;

  ${contentShow}

  &[data-state='closed'] {
    ${hide};
  }
`
