import { css } from '@linaria/core'

export const container = css`
  display: flex;
  justify-content: center;
`

export const trigger = css`
  background: none;
  align-self: center;
  cursor: pointer;
`

export const toggleContainer = css`
  height: 4.4rem;
  width: 4.4rem;
  align-self: center;
`
