import { css } from '@linaria/core'

export const wrapper = css`
  display: flex;
`

export const calendar = css`
  font-size: 1.6rem;
  border-radius: 0.4rem;

  .react-datepicker__header h2 {
    font-size: 1.4rem;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 3rem;
    padding: 0.6rem 0.8rem;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--today {
    /* TODO: Add the correct color */
  }
`
