import { baseStyles, VARIANT_STYLES } from './Pill.styles'
import { Primitive } from '../Primitives'
import { alterProps, cs } from '../../utils'

type Variant = 'primary' | 'secondary' | 'tertiary'

interface PillProps extends Primitive.DivProps {
  variant?: Variant
  color?: string
}

export const Pill = alterProps(Primitive.div)<PillProps>(props => {
  const { className, variant = 'primary', color, ...rest }: typeof props = props
  return {
    className: cs(className, baseStyles, VARIANT_STYLES[variant]),
    style: { background: color },
    ...rest,
  }
})
