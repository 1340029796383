import { ApolloLink, createHttpLink } from '@apollo/client'
import { createLink as createFileUploadLink } from 'apollo-absinthe-upload-link'

import { isOperationWithFiles } from './isOperationWithFiles'

export const createConnectionLink = (uri: string) => {
  const graphqlLink = createHttpLink({ uri })

  /**
   * The apollo-absinthe-upload-link createLink is basically a
   * wrapper component that allows the files to work with the elixir internals.
   *
   * We opted by using this Link only for mutations with files because they
   * are not updated as often as the "apollo-link-http" is and we are isolating
   * this logic to a couple of mutations
   */
  const fileConnection = createFileUploadLink({ uri: uri })

  return new ApolloLink((operation, forward) => {
    if (isOperationWithFiles(operation)) {
      return fileConnection.request(operation, forward)
    }

    return graphqlLink.request(operation, forward)
  })
}
